import { FUNNEL_STATUS } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useGetFunnelDetails } from '../funnels.api';
import { FormFieldNames } from './FunnelContext';

const useEditDraftFunnel = (funnelId: string | null | undefined) => {
  const { data: funnelData, isLoading, mutate, error } = useGetFunnelDetails(funnelId, {});

  const funnelLevels = useMemo(
    () =>
      funnelData?.levels.map((level) => ({
        ...level,
        id: String(Math.random()),
      })),
    [funnelData?.levels],
  );

  const initialFormValues = useMemo(() => {
    return {
      [FormFieldNames.name]: funnelData?.name ?? '',
      [FormFieldNames.status]: FUNNEL_STATUS.DRAFT,
      [FormFieldNames.conversionRate]: funnelData?.expectedConversionRate,
    };
  }, [funnelData?.expectedConversionRate, funnelData?.name]);

  const { showErrorNotification } = useAddNotification();
  const history = useHistory();
  const translate = useTranslate();

  useEffect(() => {
    if (funnelData && funnelData?.status !== FUNNEL_STATUS.DRAFT) {
      showErrorNotification(2000, translate('funnels.error.edit.onlyDrafts'));
      history.push('/behaviour/funnels');
    }
  }, [funnelData, history, showErrorNotification, translate]);

  useEffect(() => {
    if (error) {
      showErrorNotification(2000, translate('funnel.error.notFound'));
      history.push(`/behaviour/funnels`);
    }
  }, [error, history, showErrorNotification, translate]);

  return {
    isLoading,
    funnelData,
    initialFormValues,
    funnelLevels: funnelLevels ?? [],
    mutate,
  };
};

export default useEditDraftFunnel;
