import { CheckmarkIcon, FolderIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import classNames from 'classnames';
import { useMemo } from 'react';
import { FUNNEL_STATUS_ENUM } from '../../funnel.types';

export const FunnelStatusCell = ({ status }: { status: FUNNEL_STATUS_ENUM }) => {
  const translate = useTranslate();

  const getIcon = useMemo(() => {
    if (status === FUNNEL_STATUS_ENUM.DRAFT) {
      return <FolderIcon className='h-18px w-18px shrink-0' color='#3C3C3C' />;
    }
    return <CheckmarkIcon className='h-18px w-18px shrink-0' color='#06A25A' />;
  }, [status]);

  const getStatusText = useMemo(() => {
    if (status === FUNNEL_STATUS_ENUM.DRAFT) {
      return translate('featureToggle.status.draft');
    }

    return translate('featureToggle.status.published');
  }, [status, translate]);

  return (
    <div
      className={classNames(
        'inline-flex items-center gap-7.5px py-9px pl-3 pr-15px text-15 leading-24 font-medium group-hover:bg-white rounded-24 whitespace-nowrap truncate',
        { 'bg-green-apple text-green-pure': status === FUNNEL_STATUS_ENUM.PUBLISHED },
        { 'bg-white-snow text-gray-charcoal': status === FUNNEL_STATUS_ENUM.DRAFT },
      )}
    >
      {getIcon}
      <div className='truncate'>{getStatusText}</div>
    </div>
  );
};
