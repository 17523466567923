import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { EditFunnelFormInputNames } from '../edit-funnel/EditFunnelModal';
import { FunnelLevelType, FUNNEL_STATUS_ENUM } from '../funnel.types';
import { useDeleteFunnel } from '../funnels-main/funnels-api-client';
import { useGetFunnelDetails } from '../funnels.api';

export type FunnelDetailsDataType = {
  key: string;
  currentConversion: number;
  conversionRate: number;
  createdAt: number;
  createdBy: string | null;
  expectedConversionRate: null | undefined | number;
  visitorLimit: null | undefined | number;
  visitorCount: number;
  levels: FunnelLevelType[];
  levelsConversionData: LevelConversionDataType[];
  name: string;
  status: FUNNEL_STATUS_ENUM;
  visitorCountInTimeRange: number;
  visitorCountInPreviousTimeRange: number;
  previousConversionRate: number;
  previousConversion: number;
  conversionRatePercentageChange: number;
  conversionPercentageChange: number;
  visitorPercentageChange: number;
};

export type LevelConversionDataType = {
  totalVisitors: number;
  dropoutsVisitors: number;
  conversionRate: number;
  dropoutsRate: number;
};

type FunnelDetailsContextType = {
  funnelData: FunnelDetailsDataType | undefined;
  refreshData: () => void;
  isValidating: boolean;
  isLoading: boolean;
  funnelLevelsContainerRef: React.RefObject<HTMLDivElement>;
  funnelId: string;
  isDeleteFunnelModalOpen: boolean;
  updateIsDeleteFunnelModalOpen: (bool: boolean) => void;
  deleteFunnelApiLoading: boolean;
  executeDeleteFunnel: (funnelId: string) => void;
  isEditFunnelModalOpen: boolean;
  updateIsEditModalOpen: (bool: boolean) => void;
  updateCurrentVisibleInputField: (inputName: EditFunnelFormInputNames) => void;
  currentVisibleInputField: EditFunnelFormInputNames;
  funnelDataError: Error | undefined;
};

const FunnelDetailsContext = createContext<FunnelDetailsContextType>({} as FunnelDetailsContextType);

const FunnelDetailsContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isDeleteFunnelModalOpen, setIsDeleteFunnelModalOpen] = useState(false);
  const [isEditFunnelModalOpen, setIsEditFunnelModalOpen] = useState(false);
  const [currentVisibleInputField, setCurrentVisibleInputField] = useState(EditFunnelFormInputNames.name);

  const { funnelId } = useParams<{ funnelId: string }>();

  const funnelLevelsContainerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const translate = useTranslate();
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const {
    data: funnelData,
    mutate,
    isValidating,
    isLoading,
    error: funnelDataError,
  } = useGetFunnelDetails(funnelId, {
    revalidateOnFocus: false,
  });

  const {
    funnels: { mutate: mutateFunnelsCount },
  } = useFeatureCounterContext();

  const {
    isLoading: deleteFunnelApiLoading,
    isSucceeded: deleteFunnelApiSucceeded,
    error: deleteFunnelApiError,
    execute: executeDeleteFunnel,
  } = useDeleteFunnel();

  useEffect(() => {
    if (deleteFunnelApiSucceeded) {
      history.push('/behaviour/funnels');
      showSuccessNotification(2000, translate('funnels.deleteSuccess'));
      mutateFunnelsCount();
    }
  }, [history, deleteFunnelApiSucceeded, showSuccessNotification, translate, mutateFunnelsCount]);

  useEffect(() => {
    if (deleteFunnelApiError) {
      showErrorNotification();
    }
  }, [deleteFunnelApiError, showErrorNotification]);

  const refreshData = useCallback(() => {
    mutate();
  }, [mutate]);

  const updateIsDeleteFunnelModalOpen = useCallback((bool: boolean) => {
    setIsDeleteFunnelModalOpen(bool);
  }, []);

  const updateIsEditModalOpen = useCallback((bool: boolean) => {
    setIsEditFunnelModalOpen(bool);
  }, []);

  const updateCurrentVisibleInputField = useCallback((inputName: EditFunnelFormInputNames) => {
    setCurrentVisibleInputField(inputName);
  }, []);

  return (
    <FunnelDetailsContext.Provider
      value={{
        funnelData,
        funnelDataError,
        isValidating,
        isLoading,
        refreshData,
        funnelLevelsContainerRef,
        funnelId,
        isDeleteFunnelModalOpen,
        updateIsDeleteFunnelModalOpen,
        deleteFunnelApiLoading,
        executeDeleteFunnel,
        isEditFunnelModalOpen,
        updateIsEditModalOpen,
        updateCurrentVisibleInputField,
        currentVisibleInputField,
      }}
    >
      {children}
    </FunnelDetailsContext.Provider>
  );
};

const useFunnelDetailsContext = () => useContext(FunnelDetailsContext);

export { FunnelDetailsContextProvider, useFunnelDetailsContext };
