import { useLocale } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { addNumberSeparator, getPercentageColors } from '@va/util/helpers';
import classNames from 'classnames';

export const VisitorsCell = ({
  visitorsInTimeRange,
  visitorPercentageChange,
}: {
  visitorsInTimeRange: number;
  visitorPercentageChange: number;
}) => {
  const { locale } = useLocale();

  return (
    <div className='flex flex-col items-end'>
      <Paragraph colorClassName='text-gray-charcoal'>{addNumberSeparator(visitorsInTimeRange, locale)}</Paragraph>
      <Paragraph
        colorClassName={classNames(getPercentageColors(visitorPercentageChange, false))}
        weight={fontWeights.medium}
        size={paragraphSizes.tiny}
      >
        {addNumberSeparator(visitorPercentageChange, locale, {
          style: 'percent',
        })}
      </Paragraph>
    </div>
  );
};

export const ConversionsCell = ({
  currentConversions,
  conversionPercentageChange,
}: {
  currentConversions: number;
  conversionPercentageChange: number;
}) => {
  const { locale } = useLocale();
  return (
    <div className='flex flex-col items-end'>
      <Paragraph colorClassName='text-gray-charcoal'>{addNumberSeparator(currentConversions, locale)}</Paragraph>
      <Paragraph
        colorClassName={classNames(getPercentageColors(conversionPercentageChange, false))}
        weight={fontWeights.medium}
        size={paragraphSizes.tiny}
      >
        {addNumberSeparator(conversionPercentageChange, locale, {
          style: 'percent',
        })}
      </Paragraph>
    </div>
  );
};

export const ConversionsRateCell = ({
  conversionRate,
  conversionRatePercentageChange,
}: {
  conversionRate: number;
  conversionRatePercentageChange: number;
}) => {
  const { locale } = useLocale();
  return (
    <div className='flex flex-col items-end'>
      <Paragraph colorClassName='text-gray-charcoal'>
        {addNumberSeparator(conversionRate, locale, { style: 'percent' })}
      </Paragraph>
      <Paragraph
        colorClassName={classNames(getPercentageColors(conversionRatePercentageChange, false))}
        weight={fontWeights.medium}
        size={paragraphSizes.tiny}
      >
        {addNumberSeparator(conversionRatePercentageChange, locale, {
          style: 'percent',
        })}
      </Paragraph>
    </div>
  );
};

export const ExpectedConversionCell = ({ expectedConversionRate }: { expectedConversionRate: number | string }) => {
  return <Paragraph>{expectedConversionRate}</Paragraph>;
};
