import { UPDATE_WEBSITES_EVENT, apiRequestResponseMsg, apiStatus, defaultTheme, defaultWebsiteId } from '@va/constants';
import {
  WEBSITE_FAILED,
  WEBSITE_SUCCEEDED,
  getAccountInformation,
  getAccountInformationSucceeded,
  requestWebsite,
  resetRequest,
  resetRequestStatus,
  setRequestStatus,
  setUserFlag,
} from '@va/dashboard/actions/api';
import { set } from '@va/dashboard/actions/app';
import { addNotification, closeModal } from '@va/dashboard/actions/ui';
import { getAccountInformation as getAccountInformationData } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getCheckLoginModal, getGlobalFilter } from '@va/dashboard/selectors/ui';
import * as Actions from '@va/standalone/shared/actions';
import * as Types from '@va/standalone/shared/actions';
import { GET_WEBSITES_SUCCEEDED, SET_ACTIVE_WEBSITE_SUCCEEDED } from '@va/standalone/shared/actions';
import * as Api from '@va/standalone/shared/api-client';
import { modalName, storageItems } from '@va/standalone/shared/constants';
import {
  getUserId,
  getUserRole,
  handleLastSelectedWebsite,
  isAccountSettingsPageSelected,
  isUserLoggedInWithCustomUrl,
  mapPlatform,
  removeCredentialsFromLocalStorage,
} from '@va/standalone/shared/helpers';
import { appHistory } from '@va/shared/router';
import {
  getActiveWebsiteId,
  getAppsumoUserId,
  getWebsites,
  getWebsitesMerged,
  isActiveWebsiteWhiteLabeled,
  isSettingActiveWebsiteInProgress,
  isSumoUser,
} from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { LocalStorage, applyTheme, getParameterByName, setDefaultTheme } from '@va/util/helpers';
import { EventBus } from '@va/util/misc';
import md5 from 'blueimp-md5';
import Immutable, { List } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';
import { all, call, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

export function* getWhiteLabelInfo() {
  try {
    let websitesData = yield select(getWebsites);
    const data = yield call(Api.getWebsites, { start: 0, length: 1 }, LocalStorage.getItem(storageItems.userId));
    websitesData = websitesData
      .set('totalWhiteLabeled', data.totalWhiteLabeled)
      .set('hasContributors', data.hasContributors);
    yield put(Actions.standalone.Api.getWebsitesSucceeded(websitesData));
  } catch (err) {
    yield put(Actions.standalone.Api.getWebsitesFailed());
  }
}

function processWebsites(websites) {
  return map(websites, (website) => {
    return {
      ...website,
      platform: mapPlatform(website.platform),
      hash: md5(website.id),
      isWebsiteOwner: website.types?.includes(UserRole.OWNER),
      sharedWebsiteRequest: website.contributorRequests?.id,
      userRole: getUserRole(website.types),
    };
  });
}

export function* mutateWebsitesSaga() {
  try {
    const data = yield call(Api.getWebsites, { withSharedWebsites: true }, getUserId());
    data.websites = processWebsites(data.websites);
    yield put(Actions.standalone.Api.getWebsitesSucceeded(data));
  } catch (error) {
    yield put(Actions.standalone.Api.getWebsitesFailed(error));
  }
}

/**
 * TODO Refactor, it does way too many things
 * For simply updating the data use mutateWebsitesSaga
 */
export function* getWebsitesSaga() {
  try {
    const data = yield call(Api.getWebsites, { withSharedWebsites: true }, getUserId());

    data.websites = processWebsites(data.websites);

    const { specialWebsiteId } = yield select(getAccountInformationData);

    // If #highlight-website-id is present in the url, the preferred website should always be set as the active website, check VISA-7462
    if (window.location.hash === '#highlight-website-id') {
      LocalStorage.removeItem(storageItems.lastWebsiteId);
    }

    const oldActiveWebsiteExists = some(data.websites, {
      id: LocalStorage.getItem(storageItems.lastWebsiteId),
    });

    if (isEmpty(data.websites)) {
      LocalStorage.removeItem(storageItems.lastWebsiteId);
    } else if (!oldActiveWebsiteExists) {
      const lastWebsiteId = isUserLoggedInWithCustomUrl() ? undefined : specialWebsiteId;
      const lastWebsiteIdExist = some(data.websites, { id: lastWebsiteId });
      let websiteToSet = lastWebsiteId ? lastWebsiteId : data.websites[0].id;
      if (!lastWebsiteIdExist) {
        yield put(requestWebsite());
        const response = yield take([WEBSITE_SUCCEEDED, WEBSITE_FAILED]);
        if (response.action === WEBSITE_FAILED) {
          websiteToSet = data.websites[0].id;
        }
      }

      handleLastSelectedWebsite(websiteToSet);
    }
    let lastWebsiteId;
    if (appHistory.location.pathname.includes('/website/')) {
      const pathNameArr = appHistory.location.pathname.split('/');
      lastWebsiteId = pathNameArr[2];
    } else {
      lastWebsiteId =
        (!isUserLoggedInWithCustomUrl() && specialWebsiteId) ||
        LocalStorage.getItem(storageItems.lastWebsiteId) ||
        defaultWebsiteId;
    }

    yield put(Actions.standalone.App.setActiveWebsite(lastWebsiteId));

    yield put(Actions.standalone.Api.getWebsitesSucceeded(data));
  } catch (error) {
    //Agency contributor is deleted from db when he has no websites
    //Redirect users to login when getWebsites fails with bad credentials error
    if (isUserLoggedInWithCustomUrl() && error.message === apiRequestResponseMsg.WEBSITES_BAD_CREDENTIALS) {
      yield put(Actions.standalone.Api.logout());
      appHistory.push('/login');
    }
    yield put(Actions.standalone.Api.getWebsitesFailed(error));
  }
}

export function* updateWebsiteLocale(action) {
  try {
    const requestData = {
      locale: action.locale,
    };
    yield call(Api.updateAccountSettings, requestData);
    yield put(Actions.standalone.Api.updateWebsiteLocaleSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.updateWebsiteLocaleFailed(error));
  } finally {
    yield delay(100);
    yield put(resetRequestStatus('updateWebsiteLocale'));
  }
}

export function* changeEmail(action) {
  try {
    const requestData = {
      email: action.data.email.trim(),
    };

    yield call(Api.updateAccountSettings, requestData);

    yield put(Actions.standalone.Api.changeEmailSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.changeEmailFailed(error));
  }
}

export function* startCreateAgencyFlow(action) {
  try {
    yield put(setRequestStatus('changeAgencyStatus', apiStatus.IN_PROGRESS));
    yield call(changeAgencyStatus, { status: true });
    yield put(Actions.standalone.Api.getAgencyThemes());
    yield call(changeWebsiteWhiteLabelStatus, {
      websiteId: action.websiteId,
      status: true,
    });
    yield put(closeModal(modalName.WHITE_LABEL_WEBSITE));
    yield put(setRequestStatus('changeAgencyStatus', apiStatus.SUCCEEDED));
    appHistory.push('/my-account/agency-white-label');
  } catch (err) {
    yield put(
      addNotification({
        title: 'notifications.generalError',
        level: 'error',
        autoDismiss: 10,
      }),
    );
  }
}

export function* changeAgencyStatus(action) {
  try {
    yield call(Api.updateAccountSettings, {
      isAgency: action.status,
    });
    yield put(getAccountInformation());
    yield put(Actions.standalone.Api.getAgencyUi());
    setDefaultTheme();
    yield put(Actions.standalone.Api.changeAgencyStatusSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.changeAgencyStatusFailed(error));
  }
}

export function* changeWebsiteWhiteLabelStatus(action) {
  try {
    yield call(Api.changeWebsiteWhiteLabelStatus, action.websiteId, {
      status: action.status,
    });
    yield call(getWhiteLabelInfo);
    yield put(Actions.standalone.Api.changeWebsiteWhiteLabelStatusSucceeded());
  } catch (err) {
    yield put(Actions.standalone.Api.changeWebsiteWhiteLabelStatusFailed());
    yield put(
      addNotification({
        title: 'notifications.generalError',
        level: 'error',
        autoDismiss: 10,
      }),
    );
  }
}

export function* updateUserInfo(action) {
  try {
    const data = yield call(Api.updateAccountSettings, action.data);
    yield put(getAccountInformationSucceeded(data));
    yield put(Actions.standalone.Api.updateUserInfoSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.updateUserInfoFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('updateUserInfo'));
  }
}

export function* changeAccountDetail(action) {
  try {
    yield call(Api.updateAccountSettings, action.data);
    yield put(Actions.standalone.Api.changeAccountDetailSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.changeAccountDetailFailed(error));
  }
}

export function* confirmUpdateEmail(action) {
  try {
    yield call(Api.confirmUpdateEmail, {
      updateEmailToken: action.token,
    });

    yield put(Actions.standalone.Api.confirmUpdateEmailSucceeded());

    yield put(getAccountInformation());
  } catch (error) {
    yield put(Actions.standalone.Api.confirmUpdateEmailFailed(error));
  }
}

export function* resetEmail(action) {
  try {
    yield call(Api.resetEmail, {
      resetEmailToken: action.token,
    });

    yield put(Actions.standalone.Api.resetEmailSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.resetEmailFailed(error));
  }
}

export function* getWebsiteOverview(action) {
  try {
    yield put(Actions.standalone.Api.initiateWebsiteOverview(action.id));
    let filter = yield select(getGlobalFilter);

    const data = yield call(Api.getWebsiteOverview, {
      id: action.id,
      filter: {
        ...filter,
        from: filter.from.clone().tz(action.timezone),
        until: filter.until.clone().tz(action.timezone),
      },
    });
    const successData = {
      [action.id]: { ...data, status: apiStatus.SUCCEEDED },
    };
    yield put(Actions.standalone.Api.getWebsiteOverviewSucceeded(successData));
  } catch (error) {
    const errorData = Immutable.Map({
      id: action.id,
      data: { status: apiStatus.FAILED, message: error },
    });
    yield put(Actions.standalone.Api.getWebsiteOverviewFailed(errorData));
  }
}

export function* deleteAccount() {
  try {
    yield call(Api.deleteAccount, {});

    yield put(Actions.standalone.Api.deleteAccountSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.deleteAccountFailed(error));
  }
}

export function* getTrackingCode() {
  try {
    const activeWebsiteId = yield select(getInstanceId);
    const response = yield call(Api.getTrackingCode, activeWebsiteId);
    yield put(Actions.standalone.Api.getTrackingCodeSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getTrackingCodeFailed(error));
  }
}

export function* removeWebsite(action) {
  try {
    const accountInfo = yield select(getAccountInformationData);
    const requestData = {
      sendDataSelected: action.sendDataSelected,
      email: accountInfo.email,
      reason: action.reason,
    };

    const data = yield call(Api.removeWebsite, requestData, action.id);

    yield put(Actions.standalone.Api.removeWebsiteSucceeded(data));
    yield put(Actions.standalone.Api.getWebsites());
    const isSumoUserValue = yield select(isSumoUser);
    if (isSumoUserValue) yield put(Actions.standalone.Api.getVouchersInfo());

    let activeWebsiteId = yield select(getActiveWebsiteId);

    if (activeWebsiteId === action.id) {
      let newActiveWebsiteId = undefined;
      LocalStorage.removeItem(storageItems.lastWebsiteId);

      const getWebsitesAction = yield take([
        Types.standalone.Api.GET_WEBSITES_SUCCEEDED,
        Types.standalone.Api.GET_WEBSITES_FAILED,
      ]);

      if (getWebsitesAction.type === Types.standalone.Api.GET_WEBSITES_SUCCEEDED) {
        const websites = yield select(getWebsites);
        const allWebsites = websites.get('websites');

        if (allWebsites.size > 0) {
          newActiveWebsiteId = allWebsites.first().get('id');
        }

        //Set new activeWebsiteId if user has websites.
        if (newActiveWebsiteId) {
          yield put(Actions.standalone.App.setActiveWebsite(newActiveWebsiteId));
        } else {
          //Reset actual activeWebsiteId from store.
          yield put(Actions.standalone.App.setActiveWebsite(defaultWebsiteId));
          // Reset siteUrl from core because last website has been deleted.
          yield put(
            set({
              isPublish: 'false',
              siteUrl: 'null',
            }),
          );
        }
      } else {
        //Reset actual activeWebsiteId from store.
        yield put(Actions.standalone.App.setActiveWebsite(defaultWebsiteId));
      }
    }
    yield put(getAccountInformation());
    //To remove the website from the website selector
    EventBus.dispatch(UPDATE_WEBSITES_EVENT);
  } catch (error) {
    yield put(Actions.standalone.Api.removeWebsiteFailed(error));
    yield put(Actions.standalone.Api.getWebsites());
  }
}

export function* rejectContributorRole(action) {
  try {
    if (action.websiteId) {
      yield call(Api.rejectContributorRole, { type: action.userRole }, action.websiteId);
    } else {
      const requestData = { confirmationToken: action.token };
      yield call(Api.rejectContributorRoleWithToken, requestData);
    }

    yield put(Actions.standalone.Api.rejectContributorRoleSucceeded());
    yield put(Actions.standalone.Api.getWebsites());
  } catch (error) {
    yield put(Actions.standalone.Api.rejectContributorRoleFailed(error));
  }
}

export function* acceptContributorRole(action) {
  try {
    if (action.websiteId) {
      yield call(Api.acceptContributorRole, { type: action.userRole }, action.websiteId);
    } else {
      const requestData = {
        confirmationToken: action.token,
        type: action.userRole,
      };
      yield call(Api.acceptContributorRoleWithToken, requestData);
      LocalStorage.removeItem(storageItems.pendingConfirmation);
    }

    yield put(Actions.standalone.Api.acceptContributorRoleSucceeded());
    yield put(Actions.standalone.Api.getWebsites());
    EventBus.dispatch(UPDATE_WEBSITES_EVENT);
    yield take([Types.standalone.Api.GET_WEBSITES_SUCCEEDED]);
    const websites = yield select(getWebsitesMerged);
    if (websites.size === 1) {
      const contributorWebsiteId = websites.toJS()[0].id;
      yield put(Actions.standalone.App.setActiveWebsite(contributorWebsiteId));
      appHistory.push(`/website/${contributorWebsiteId}`);
    }
  } catch (error) {
    yield put(Actions.standalone.Api.acceptContributorRoleFailed(error));
  }
}

export function* leaveWebsite(action) {
  try {
    const accountInfo = yield select(getAccountInformationData);
    const currentWebsiteId = yield select(getInstanceId);

    yield call(Api.deleteWebsiteContributorAccepted, accountInfo.id, action.websiteId);

    yield put(Actions.standalone.Api.leaveWebsiteSucceeded());
    yield put(Actions.standalone.Api.getWebsites());
    yield take([GET_WEBSITES_SUCCEEDED]);

    if (action.websiteId === accountInfo.specialWebsiteId) {
      const websitesData = yield select(getWebsites);
      const { id: websiteId, url: websiteUrl } = websitesData.get('websites').first()?.toJS() ?? {};

      yield put(Actions.standalone.Api.updateUserInfo({ specialWebsite: websiteId }));
      yield put(setUserFlag('defaultWebsiteName', websiteUrl));

      if (websiteId && currentWebsiteId === accountInfo.specialWebsiteId) {
        yield put(Actions.standalone.App.setActiveWebsite(websiteId));
      }
    }

    //To remove the website from the website selector
    EventBus.dispatch(UPDATE_WEBSITES_EVENT);
  } catch (error) {
    yield put(Actions.standalone.Api.leaveWebsiteFailed(error));
  }
}

function* logout() {
  const checkLoginModal = yield select(getCheckLoginModal);

  if (checkLoginModal && checkLoginModal.open) {
    yield put(closeModal(modalName.CHECK_LOGIN));
  }
  if (window.logoId) window.logoId = null; //To set logoId null otherwise if user loggs in other account without any refresh it will take the previouly setted logoId and will fail to get the logo.
  try {
    window.$chatwoot?.reset();
    window.$chatwoot?.toggleBubbleVisibility('hide');
  } catch (e) {
    console.error(e);
  }
  removeCredentialsFromLocalStorage();
  appHistory.push('/login');
}

function* findMigrationsUser(action) {
  try {
    let response = yield call(Api.findMigrationsUser, action.data);
    yield put(Actions.standalone.Api.findMigrationsUserSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.findMigrationsUserFailed(error));
  }
}

function* createWebsiteForMigration(action) {
  try {
    yield call(Api.createWebsiteForMigration, { token: action.data.token }, action.data.id);

    yield put(Actions.standalone.Api.createWebsiteForMigrationSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.createWebsiteForMigrationFailed(error));
  }
}

function* createNewUserForMigration(action) {
  try {
    const requestData = {
      token: getParameterByName('token'),
      email: action.data.email.trim(),
      password: action.data.password,
      firstName: (action.data.firstName || '').trim(),
      lastName: (action.data.lastName || '').trim(),
      companyName: (action.data.companyName || '').trim(),
      privacyTermsAccepted: action.data.termsAndDpaAccepted || false,
      dpaAccepted: action.data.termsAndDpaAccepted || false,
      businessConditionAccepted: action.data.termsAndDpaAccepted || false,
      newsletterSubscribed: action.data.newsletterSubscribed || false,
    };
    const response = yield call(Api.createNewUserForMigration, requestData);

    yield put(Actions.standalone.Api.createNewUserForMigrationSucceeded());
    const data = {
      token: requestData.token,
      id: response.id,
    };
    yield put(Actions.standalone.Api.createWebsiteForMigration(data));
    yield take(Types.standalone.Api.CREATE_WEBSITE_FOR_MIGRATION_SUCCEEDED);
    yield put(Actions.standalone.Api.backgroundLogin(action.data));
    yield take(Types.standalone.App.INITIALIZE_SUCCEEDED);
    appHistory.push('/');
  } catch (error) {
    yield put(Actions.standalone.Api.createNewUserForMigrationFailed(error));
  }
}

function* createNewSubscriber(action) {
  try {
    yield call(Api.createNewSubscriber, action.data);
    yield put(Actions.standalone.Api.newSubscriberSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.newSubscriberFailed(error));
  }
}

function* addVoucher(action) {
  try {
    const voucher = action.data.get('voucher');
    const userId = yield select(getAppsumoUserId);
    const data = {
      voucher: voucher,
    };
    yield call(Api.addVoucher, data, userId);
    yield put(Actions.standalone.Api.getVouchersInfo());
    yield put(Actions.standalone.Api.addVoucherSucceeded());
    yield put(Actions.standalone.App.updateVouchersList(voucher));
  } catch (error) {
    yield put(Actions.standalone.Api.addVoucherFailed(error));
  }
}
function* getVouchers() {
  try {
    const userId = yield select(getAppsumoUserId);
    let response = yield call(Api.getVouchers, userId);
    yield put(Actions.standalone.Api.getVouchersSucceeded({ data: response }));
    let vouchers = [];
    for (let i = 0; i < response.length; i++) {
      vouchers.push(response[i].voucher);
    }
    yield put(Actions.standalone.App.setVouchersList(List(vouchers)));
  } catch (error) {
    yield put(Actions.standalone.Api.getVouchersFailed(error));
  }
}
function* getVouchersInfo() {
  try {
    const userId = yield select(getAppsumoUserId);
    const response = yield call(Api.getVouchersInfo, userId);
    yield put(Actions.standalone.Api.getVouchersInfoSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getVouchersInfoFailed(error));
  }
}

function* applyLifetimeDeal(action) {
  try {
    yield call(Api.applyLifetimeDeal, action.data);
    yield put(Actions.standalone.Api.applyLifetimeDealSucceeded());
    yield put(Actions.standalone.Api.getWebsites());
    yield put(Actions.standalone.Api.getVouchersInfo());
  } catch (error) {
    yield put(Actions.standalone.Api.applyLifetimeDealFailed(error));
  }
}

function* getGoogleImporterAuthUrl() {
  try {
    const response = yield call(Api.getGoogleImporterAuthUrl);
    yield put(Actions.standalone.Api.getGoogleImporterAuthUrlSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getGoogleImporterAuthUrlFailed(error));
  }
}

function* getGoogleImporterViews(action) {
  try {
    const response = yield call(Api.getGoogleImporterViews, action.queryParams);
    yield put(Actions.standalone.Api.getGoogleImporterViewsSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getGoogleImporterViewsFailed(error));
  }
}

function* startGoogleImporter(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.startGoogleImporter, websiteId, action.viewId);
    yield put(Actions.standalone.Api.startGoogleImporterSucceeded(response));
    yield put(Actions.standalone.Api.getGoogleImporterProgress());
  } catch (error) {
    yield put(Actions.standalone.Api.startGoogleImporterFailed(error));
  }
}

function* getGoogleImporterProgress() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getGoogleImporterProgress, websiteId);
    yield put(Actions.standalone.Api.getGoogleImporterProgressSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getGoogleImporterProgressFailed(error));
  }
}

function* updateGoogleImporterProgress() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getGoogleImporterProgress, websiteId);
    yield put(Actions.standalone.Api.getGoogleImporterProgressSucceeded(response));
  } catch (error) {
    console.error(error);
  }
}

function* getAgencyThemes() {
  try {
    const data = yield call(Api.getAgencyThemes, LocalStorage.getItem(storageItems.userId));
    yield put(Actions.standalone.Api.getAgencyThemesSucceeded({ data: data }));
  } catch (error) {
    yield put(Actions.standalone.Api.getAgencyThemesFailed(error));
  }
}

function* fetchAgencyUi() {
  if (isUserLoggedInWithCustomUrl()) {
    const response = yield call(Api.getCustomUrlAgencyUi, window.location.origin);
    yield call(setCustomTheme, response);
    return response;
  }

  if (isAccountSettingsPageSelected()) {
    // TODO API should remove the Array, since it will only have one element
    const [response] = yield call(Api.getAgencyUi);
    return response;
  }

  const activeWebsiteId = yield select(getActiveWebsiteId);
  const isActiveWebsiteSettingInProgress = yield select(isSettingActiveWebsiteInProgress);
  if (isActiveWebsiteSettingInProgress) {
    yield take([SET_ACTIVE_WEBSITE_SUCCEEDED]);
  }
  const hasWhiteLabel = yield select(isActiveWebsiteWhiteLabeled);

  if (!hasWhiteLabel) return null;

  // TODO API should remove the Array, since it will only have one element
  const [response] = yield call(Api.getWebsiteAgencyUi, activeWebsiteId);
  yield call(setCustomTheme, response);
  return response;
}

function* getAgencyUi() {
  try {
    const data = yield call(fetchAgencyUi);

    if (data === null) {
      // Website does not have whitelabel enabled
      yield put(resetRequest('getAgencyUi'));
      setDefaultTheme();
      return;
    }

    yield put(Actions.standalone.Api.getAgencyUiSucceeded({ data }));
  } catch (error) {
    yield put(Actions.standalone.Api.getAgencyUiFailed(error));
  }
}

const defaultColors = {
  primary: defaultTheme.PRIMARY_COLOR,
  secondary: defaultTheme.SECONDARY_COLOR,
  tertiary: defaultTheme.TERTIARY_COLOR,
  positive: defaultTheme.POSITIVE_COLOR,
  negative: defaultTheme.NEGATIVE_COLOR,
  neutral: defaultTheme.NEUTRAL_COLOR,
};

function setCustomTheme({ theme, fontName, logoId }) {
  const root = document.documentElement;
  if (root) {
    const colors = {
      ...defaultColors,
      ...theme?.colors,
    };
    const selectedFontName = fontName ?? 'Inter';

    applyTheme({ fontName: selectedFontName, colors });
    window.logoId = logoId;
  }
}

function* getAgencyQuaterlyCommission() {
  try {
    const data = yield call(Api.getAgencyQuaterlyCommission);
    yield put(
      Actions.standalone.Api.getAgencyQuaterlyCommissionSucceeded({
        data: data,
      }),
    );
  } catch (error) {
    yield put(Actions.standalone.Api.getAgencyQuaterlyCommissionFailed(error));
  }
}

export function* watchers() {
  yield all([
    takeLatest(Types.standalone.Api.APPLY_LIFETIME_DEAL_REQUEST, applyLifetimeDeal),
    takeLatest(Types.standalone.Api.GET_VOUCHERS, getVouchers),
    takeLatest(Types.standalone.Api.GET_VOUCHERS_INFO, getVouchersInfo),
    takeLatest(Types.standalone.Api.ADD_VOUCHER, addVoucher),
    takeLatest(Types.standalone.Api.GET_TRACKING_CODE_REQUEST, getTrackingCode),
    takeLatest(Types.standalone.Api.CREATE_NEW_USER_FOR_MIGRATION_REQUEST, createNewUserForMigration),
    takeLatest(Types.standalone.Api.CREATE_WEBSITE_FOR_MIGRATION_REQUEST, createWebsiteForMigration),
    takeLatest(Types.standalone.Api.GET_WEBSITES_REQUEST, getWebsitesSaga),
    takeLatest(Types.standalone.Api.MUTATE_WEBSITES, mutateWebsitesSaga),
    takeLatest(Types.standalone.Api.UPDATE_WEBSITE_LOCALE_REQUEST, updateWebsiteLocale),
    takeLatest(Types.standalone.Api.CHANGE_EMAIL_REQUEST, changeEmail),
    takeLatest(Types.standalone.Api.UPDATE_USER_INFO_REQUEST, updateUserInfo),
    takeLatest(Types.standalone.Api.CHANGE_AGENCY_STATUS_REQUEST, changeAgencyStatus),
    takeLatest(Types.standalone.Api.CHANGE_WEBSITE_WHITE_LABEL_STATUS_REQUEST, changeWebsiteWhiteLabelStatus),
    takeLatest(Types.standalone.Api.CHANGE_ACCOUNT_DETAIL_REQUEST, changeAccountDetail),
    takeLatest(Types.standalone.Api.CONFIRM_UPDATE_EMAIL_REQUEST, confirmUpdateEmail),
    takeLatest(Types.standalone.Api.RESET_EMAIL_REQUEST, resetEmail),
    takeEvery(Types.standalone.Api.GET_WEBSITE_OVERVIEW, getWebsiteOverview),
    takeLatest(Types.standalone.Api.DELETE_ACCOUNT_REQUEST, deleteAccount),
    takeLatest(Types.standalone.Api.REMOVE_WEBSITE_REQUEST, removeWebsite),
    takeLatest(Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_REQUEST, acceptContributorRole),
    takeLatest(Types.standalone.Api.REJECT_CONTRIBUTOR_ROLE_REQUEST, rejectContributorRole),
    takeLatest(Types.standalone.Api.LEAVE_WEBSITE_REQUEST, leaveWebsite),
    takeLatest(Types.standalone.Api.LOGOUT, logout),
    takeLatest(Types.standalone.Api.FIND_MIGRATIONS_USER_REQUEST, findMigrationsUser),
    takeLatest(Types.standalone.Api.CREATE_NEW_SUBSCRIBER, createNewSubscriber),
    takeLatest(Types.standalone.Api.GET_GOOGLE_IMPORTER_AUTH_URL_REQUEST, getGoogleImporterAuthUrl),
    takeLatest(Types.standalone.Api.GET_GOOGLE_IMPORTER_VIEWS_REQUEST, getGoogleImporterViews),
    takeLatest(Types.standalone.Api.START_GOOGLE_IMPORTER_REQUEST, startGoogleImporter),
    takeLatest(Types.standalone.Api.GET_GOOGLE_IMPORTER_PROGRESS_REQUEST, getGoogleImporterProgress),
    takeLatest(Types.standalone.Api.UPDATE_GOOGLE_IMPORTER_PROGRESS, updateGoogleImporterProgress),
    takeLatest(Types.standalone.Api.GET_AGENCY_UI_REQUEST, getAgencyUi),
    takeLatest(Types.standalone.Api.GET_WEBSITE_AGENCY_UI_REQUEST, getAgencyUi),
    takeLatest(Types.standalone.Api.GET_AGENCY_THEMES_REQUEST, getAgencyThemes),
    takeLatest(Types.standalone.Api.GET_CUSTOM_URL_AGENCY_UI_REQUEST, getAgencyUi),
    takeLatest(Types.standalone.App.START_CREATE_AGENCY_FLOW, startCreateAgencyFlow),
    takeLatest(Types.standalone.Api.GET_AGENCY_QUATERLY_COMMISSION_REQUEST, getAgencyQuaterlyCommission),
  ]);
}
