import { apiGateway } from '@va/api-client';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { patch, post, remove } from '@va/http-client';
import { useFiltersContext } from '@va/shared/feature-filters';
import { storageItems } from '@va/standalone/shared/constants';
import { RecordingsDataType, RecordingsFiltersType } from '@va/types/recordings';
import { hashString, isStandaloneApp, isWixMiniApp, LocalStorage } from '@va/util/helpers';
import { getSwrError, isSWRLoading, useAsyncFunction } from '@va/util/hooks';
import { RequestCanceler, useCancelOnUnmount } from '@va/util/misc';
import { DEVICE_TYPES, PredefinedFilters, WixConversionFunnel } from '@va/wix-mini/shared/types';
import { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useRecordingsContext } from './context';

export const WIX_PROPRIETARY_FILTERS: Record<string, string> = {
  PAGE_INTERACTIONS: 'navigationFlow.pageInteractions',
};

type QueryData = {
  from: string | Moment;
  until: string | Moment;
  unit: string;
  length: number;
  search: string;
  start: number;
  order: string;
};

const regularFetcher = (
  url: string,
  queryData: QueryData,
  filters: Record<string, unknown>,
  { signal }: { signal: AbortSignal },
) => {
  return post<RecordingsDataType>(url, queryData, filters, { signal });
};

async function getJobIdFromSessionStorage(websiteId: string, filters: PredefinedFilters) {
  const hash = await hashString(JSON.stringify({ websiteId, filters }));
  const storedData = sessionStorage.getItem(hash);

  if (!storedData) return null;
  return Promise.resolve(storedData);
}

type WixTimeFilters =
  | {
      predefinedTimePeriod: string;
      customTimePeriod?: never;
    }
  | {
      predefinedTimePeriod?: never;
      customTimePeriod: {
        startDate: string;
        endDate: string;
      };
    };

type WixSessionFilters =
  | {
      navigationFlow: {
        pageInteractions: Array<string>;
      };
      conversionFunnel?: never;
      deviceType?: never;
    }
  | {
      navigationFlow?: never;
      conversionFunnel: WixConversionFunnel;
      deviceType?: never;
    }
  | {
      navigationFlow?: never;
      conversionFunnel?: never;
      deviceType: { type: DEVICE_TYPES };
    };

type WixRequestBody = {
  instanceId: string;
} & WixTimeFilters &
  WixSessionFilters;

const getJobId = async (websiteId: string, filters: PredefinedFilters) => {
  const storedJobId = await getJobIdFromSessionStorage(websiteId, filters);

  if (storedJobId) {
    return Promise.resolve(storedJobId);
  }

  const requestBody: Partial<WixRequestBody> = {
    instanceId: websiteId,
    predefinedTimePeriod: filters.predefinedTimePeriod!,
  };

  if (filters.deviceType) {
    requestBody['deviceType'] = {
      type: filters.deviceType as DEVICE_TYPES,
    };
  }

  if (filters.navigationFlow) {
    requestBody['navigationFlow'] = filters.navigationFlow;
  }

  const response = await apiGateway.post<{ payload: string }>('/api/v1/webhooks/wix/sessions-job', requestBody);
  const jobId = response.payload;

  const hash = await hashString(JSON.stringify({ websiteId, filters }));
  sessionStorage.setItem(hash, jobId);

  return jobId;
};

const getWixSessionsByJobId = async (
  instanceId: string,
  jobId: string,
  queryData: Pick<QueryData, 'start' | 'length'>,
) => {
  const requestBody = {
    instanceId,
    jobId,
    pageSize: queryData.length,
    page: Math.floor(queryData.start / queryData.length) + 1,
  };

  try {
    const response = await apiGateway.post<{
      payload: {
        sessionIds: Array<string>;
        total: number;
      };
    }>('/api/v1/webhooks/wix/sessions', requestBody);
    return response.payload.sessionIds ?? [];
  } catch (error) {
    const predefinedFilters = getPredefinedFiltersFromSessionStorage();
    const hash = await hashString(JSON.stringify({ websiteId: instanceId, filters: predefinedFilters }));
    sessionStorage.removeItem(hash);
    return [];
  }
};

function getPredefinedFiltersFromSessionStorage() {
  const predefinedFilters = sessionStorage.getItem('predefinedFilters');

  if (!predefinedFilters) return null;

  try {
    return JSON.parse(predefinedFilters) as PredefinedFilters;
  } catch (e) {
    return null;
  }
}

const wixMiniFetcher = async (
  url: string,
  queryData: QueryData,
  filters: Record<string, unknown>,
  { signal }: { signal: AbortSignal },
) => {
  const shortInstanceId = LocalStorage.getItem(storageItems.shortInstanceId);
  const predefinedFilters = getPredefinedFiltersFromSessionStorage();

  if (!predefinedFilters?.navigationFlow && !predefinedFilters?.deviceType) {
    return post<RecordingsDataType>(url, queryData, filters, { signal });
  }

  const jobId = await getJobId(shortInstanceId!, predefinedFilters);
  const wixSessionIds = await getWixSessionsByJobId(shortInstanceId!, jobId, queryData);

  delete filters[WIX_PROPRIETARY_FILTERS.PAGE_INTERACTIONS];

  filters = {
    ...filters,
    wixSessionIds: wixSessionIds,
  };

  return post<RecordingsDataType>(url, { ...queryData, start: 0 }, filters, { signal });
};

export const useVisitorRecordings = ({
  pageNumber,
  pageSize = 10,
  initialFilters,
}: {
  pageNumber: number;
  pageSize?: number;
  initialFilters?: Partial<RecordingsFiltersType>;
}) => {
  const websiteId = useSelector(getInstanceId);
  const { appliedFilterValues } = useFiltersContext<Partial<RecordingsFiltersType>>();

  const filters = { ...initialFilters, ...appliedFilterValues };

  if (appliedFilterValues.duration) {
    filters.duration = {
      min: appliedFilterValues.duration.min * 1000,
      max: appliedFilterValues.duration.max * 1000,
    };
  }

  if (appliedFilterValues.adCampaignLabels && typeof appliedFilterValues.adCampaignLabels === 'string') {
    filters.adCampaignLabels = [appliedFilterValues.adCampaignLabels];
  }

  const { from, unit, until } = useCurrentPeriodFilter();
  const queryData: QueryData = useMemo(
    () => ({
      from,
      unit,
      until,
      length: pageSize,
      search: '',
      start: pageNumber * pageSize,
      order: 'createdAt',
    }),
    [from, pageSize, pageNumber, unit, until],
  );

  const reqId = useMemo(() => RequestCanceler.generateId(), []);
  useCancelOnUnmount(reqId);

  const fetcher = useCallback(
    async (url: string, queryData: QueryData, filters: Record<string, unknown>) => {
      const signal = RequestCanceler.onRequestStart(reqId);
      let res: RecordingsDataType;
      if (!isWixMiniApp()) {
        res = await regularFetcher(url, queryData, filters, { signal });
      } else {
        res = await wixMiniFetcher(url, queryData, filters, { signal });
      }

      RequestCanceler.onRequestEnd(reqId);
      return res;
    },
    [reqId],
  );

  const swr = useSWR([`/v2/websites/${websiteId}/sessions/recordings`, queryData, filters], fetcher, {
    revalidateOnFocus: true,
    revalidateIfStale: true,
    onError: () => {
      RequestCanceler.onRequestEnd(reqId);
    },
  });

  return { ...swr, isLoading: isSWRLoading(swr), error: getSwrError(swr) };
};

export const useUpdateBulkRecordings = () => {
  const { websiteId, from, until } = useCurrentPeriodFilter();
  const { appliedFilterValues } = useFiltersContext();

  const asyncFunc = (body: { seen: boolean } | { star: boolean }) => {
    const payload = {
      filter: {
        from,
        until,
        ...appliedFilterValues,
      },
      update: {
        ...body,
      },
    };
    return patch(`/websites/${websiteId}/session-extras/all`, {}, payload);
  };

  const response = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(response.isSucceeded);
  return response;
};

export const useGetRecordingLink = (recordingId: string) => {
  const [success, setSuccess] = useState(false);
  const websiteId = useSelector(getInstanceId);
  const origin = window.location.origin;
  const query = window.location.search;
  const basePath = isStandaloneApp() ? `/website/${websiteId}` : '';
  const link = `${origin}${basePath}/behaviour/recordings/${recordingId}${query}`;
  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }, [link]);
  return { copyLink, success, link };
};

export const useStarRecordings = (sessionIds: string[]) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (star: boolean) => patch(`/websites/${websiteId}/session-extras`, {}, { sessionIds, star });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc, { throwErrorBack: true });
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useMarkSeenRecording = (sessionIds: string[]) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (seen: boolean) => patch(`/websites/${websiteId}/session-extras`, {}, { sessionIds, seen });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc, { throwErrorBack: true });
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useDeleteBulkRecordings = () => {
  const { websiteId, from, until } = useCurrentPeriodFilter();
  const { appliedFilterValues } = useFiltersContext();

  const asyncFunc = () => {
    const payload = {
      filter: {
        from,
        until,
        ...appliedFilterValues,
      },
    };
    return remove(`/v2/websites/${websiteId}/recordings/bulk`, {}, payload);
  };

  const response = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(response.isSucceeded);
  return response;
};

export const useDeleteRecordingsApi = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (sessionIds: string[]) =>
    remove(`/v2/websites/${websiteId}/sessions/recordings`, {}, { sessionIds });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useMutateRecordings = (shouldMutate: boolean) => {
  const { mutateRecordings } = useRecordingsContext();
  useEffect(() => {
    if (!shouldMutate) return;
    setTimeout(() => {
      if (!mutateRecordings) return;
      mutateRecordings();
    }, 10);
  }, [mutateRecordings, shouldMutate]);
};
