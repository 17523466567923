import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { confirmUpdateEmail, resetEmail } from '@va/standalone/shared/actions';
import { appHistory } from '@va/shared/router';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { getParameterByName, someInProgress } from '@va/util/helpers';

import { apiStatus, modalName, pathName } from '@va/constants';
import ConfirmUpdateEmailComponent from './ConfirmUpdateEmailComponent';

import { closeModal, openModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';

const ConfirmUpdateEmailContainer: React.FC<{
  isConfirmUpdateEmail: boolean;
  isResetEmail: boolean;
}> = ({ isConfirmUpdateEmail, isResetEmail }) => {
  const dispatch = useDispatch();

  const updateEmailStatus = useSelector((state) => getApiRequestStatus(state, 'confirmUpdateEmail'));
  const resetEmailStatus = useSelector((state) => getApiRequestStatus(state, 'resetEmail'));
  const isEmailChangeModalOpen = useSelector((state) => isModalOpen(state, modalName.changeEmailConfirmation));
  const changeEmailStatus = isConfirmUpdateEmail ? updateEmailStatus : resetEmailStatus;

  useEffect(() => {
    const token = getParameterByName('token');
    if (isConfirmUpdateEmail) {
      dispatch(confirmUpdateEmail(token));
    }
    if (isResetEmail) {
      dispatch(resetEmail(token));
    }
    dispatch(openModal(modalName.changeEmailConfirmation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseModal = () => {
    if (someInProgress(updateEmailStatus, resetEmailStatus)) {
      return;
    }

    dispatch(closeModal(modalName.changeEmailConfirmation));
    appHistory.push(pathName.accountSettings);
  };

  return (
    <ModalWrapper
      closeModal={onCloseModal}
      isOpen={isEmailChangeModalOpen}
      showCloseButton={!(changeEmailStatus === apiStatus.IN_PROGRESS)}
    >
      <ConfirmUpdateEmailComponent changeEmailStatus={changeEmailStatus} closeModal={onCloseModal} />
    </ModalWrapper>
  );
};

export default memo(ConfirmUpdateEmailContainer);
