import { FUNNEL_STATUS, validationTranslationKeys } from '@va/constants';
import { setNewFunnelUrlPattern } from '@va/dashboard/actions/ui';
import { getNewFunnelUrlPattern } from '@va/dashboard/selectors/ui';
import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { EndChainIcon, MiddleChainIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { T3_URL_PARAMS } from '@va/standalone/shared/constants';
import { appHistory } from '@va/shared/router';
import { IconWithBackground } from '@va/ui/design-system';
import { removeUrlParameter } from '@va/util/helpers';
import { usePrevious } from '@va/util/hooks';
import { FormikErrors, useFormik } from 'formik';
import { isEqual } from 'lodash';
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import { FunnelLevelType, FunnelLevelTypes, FunnelLevelWithIdType } from '../funnel.types';
import { useEditFunnel } from '../funnels-main/funnels-api-client';
import useCreateFunnel from './useCreateFunnel';
import useEditDraftFunnelData from './useEditDraftFunnelData';

export enum FunnelPageModeEnum {
  createFunnel = 'createFunnel',
  editFunnel = 'editFunnel',
}

type FunnelInfoValuesType = {
  name: string;
  status: number;
  conversionRate: number | null | undefined;
};

type FunnelContextType = {
  funnelInfoValues: FunnelInfoValuesType;
  funnelInfoValuesErrors: FormikErrors<FunnelInfoValuesType>;
  handleFunnelInfoValuesChange: (e: React.ChangeEvent<any>) => void;
  funnelLevels: FunnelLevelWithIdType[];
  addNewFunnelLevel: () => void;
  deleteFunnelLevel: (levelId: string) => void;
  updateFunnelLevel: (levelId: string, data: Object) => void;
  submitForm: () => Promise<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FunnelInfoValuesType>>;
  isLoading: boolean;
  generalError: string;
  validateForm: () => Promise<Object>;
  validateMinimumLevelsCount: () => boolean;
  editModeActive: boolean;
  isLoadingFunnelToEditData: boolean;
  hasSomethingChanged: boolean;
};

export enum FormFieldNames {
  name = 'name',
  status = 'status',
  conversionRate = 'conversionRate',
}

const getDefaultEmptyFunnelLevel = (initialValues: Partial<FunnelLevelType> = {}) => ({
  id: String(Math.random()),
  name: '',
  url: '',
  initialUrl: initialValues.url,
  type: FunnelLevelTypes.STATIC_PAGE,
  match: 0,
  ...initialValues,
});

const defaultInitialFormValues = {
  [FormFieldNames.name]: '',
  [FormFieldNames.status]: FUNNEL_STATUS.ACTIVE,
  [FormFieldNames.conversionRate]: null,
};

const FunnelContext = createContext({} as FunnelContextType);

const FunnelContextProvider: React.FC<PropsWithChildren<{ mode: FunnelPageModeEnum }>> = ({ children, mode }) => {
  const editModeActive = mode === FunnelPageModeEnum.editFunnel;

  const { funnelId } = useParams<{ funnelId?: string }>(); // Available only for the edit funnel page

  const {
    isLoading: isLoadingFunnelToEditData,
    initialFormValues: editModeInitialFormValue,
    funnelLevels: editModeInitialFunnelLevels,
    mutate: mutateFunnelToEditData,
  } = useEditDraftFunnelData(funnelId);

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const initialUrlPattern = useSelector(getNewFunnelUrlPattern);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const {
    execute: createFunnel,
    isLoading: isLoadingCreateFunnel,
    error: apiErrorCreateFunnel,
    isSucceeded: createFunnelSucceeded,
  } = useCreateFunnel();
  const {
    execute: updateFunnel,
    isLoading: isLoadingEditDraftFunnel,
    error: apiErrorEditDraftFunnel,
    isSucceeded: editDraftFunnelSucceeded,
  } = useEditFunnel();

  const [funnelLevels, setFunnelLevels] = useState<Array<FunnelLevelWithIdType>>(
    editModeActive
      ? editModeInitialFunnelLevels
      : [getDefaultEmptyFunnelLevel({ url: initialUrlPattern ?? '', type: FunnelLevelTypes.URL_PATTERN })],
  );

  const {
    funnels: { mutate: mutateFunnelsCount },
  } = useFeatureCounterContext();

  // Reinitializing the funnels local state after the loading state is over and we have data from api
  useEffect(() => {
    if (!editModeActive || editModeInitialFunnelLevels.length === 0) return;

    setFunnelLevels((prev) => {
      if (prev.length > 0) return prev;
      return editModeInitialFunnelLevels;
    });
  }, [editModeActive, editModeInitialFunnelLevels]);

  const [generalError, setGeneralError] = useState('');

  const updateGeneralError = useCallback((message: string) => {
    setGeneralError(message);
    setTimeout(() => {
      setGeneralError('');
    }, 4000);
  }, []);

  const {
    values: funnelInfoValues,
    handleChange: handleFunnelInfoValuesChange,
    errors: funnelInfoValuesErrors,
    setFieldValue,
    submitForm,
    validateForm,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: editModeActive ? editModeInitialFormValue : defaultInitialFormValues,
    onSubmit: async (values) => {
      const createdLevels = getCreatedFunnelLevels();

      if (createdLevels.length === 0) {
        updateGeneralError(translate('funnels.createFunnel.error.minimumLevels'));
        return;
      }

      const payload = {
        ...values,
        levels: createdLevels.map((level) => ({
          url: level.url,
          type: level.type,
          match: level.match,
          name: level.name,
        })),
      };
      if (editModeActive) {
        updateFunnel(funnelId!, payload);
      } else {
        await createFunnel(payload);
        const updatedURL = removeUrlParameter(T3_URL_PARAMS.newFunnelUrl);
        appHistory.replace(updatedURL);
        dispatch(setNewFunnelUrlPattern(null));
      }
    },
    validationSchema: getValidationSchema(translate),
  });

  const previousFunnelInfoValues = usePrevious(funnelLevels);

  const getCreatedFunnelLevels = useCallback(() => {
    return funnelLevels.filter((level) => level.url);
  }, [funnelLevels]);

  const validateMinimumLevelsCount = useCallback(() => {
    const createdLevels = getCreatedFunnelLevels();
    if (createdLevels.length === 0) {
      updateGeneralError(translate('funnels.createFunnel.error.minimumLevels'));
      return true;
    }
    return false;
  }, [getCreatedFunnelLevels, translate, updateGeneralError]);

  const addNewFunnelLevel = useCallback(() => {
    setFunnelLevels((prev) => [...prev, getDefaultEmptyFunnelLevel()]);
  }, []);

  const deleteFunnelLevel = useCallback((id: string) => {
    setFunnelLevels((prev) => prev.filter((level) => level.id !== id));
  }, []);

  const updateFunnelLevel = useCallback((id: string, data: Object) => {
    setFunnelLevels((prev) =>
      prev.map((level) => {
        if (level.id !== id) return level;
        return {
          ...level,
          ...data,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (createFunnelSucceeded) {
      showSuccessNotification();
      history.push(`/behaviour/funnels`);
      mutateFunnelsCount();
    }

    if (editDraftFunnelSucceeded) {
      showSuccessNotification();
      mutateFunnelToEditData(); // The cached data should be mutated
      history.push(`/behaviour/funnels`);
    }
  }, [
    createFunnelSucceeded,
    editDraftFunnelSucceeded,
    history,
    mutateFunnelToEditData,
    mutateFunnelsCount,
    showSuccessNotification,
  ]);

  useEffect(() => {
    if (apiErrorCreateFunnel || apiErrorEditDraftFunnel) {
      showErrorNotification();
    }
  }, [apiErrorCreateFunnel, apiErrorEditDraftFunnel, showErrorNotification]);

  const hasSomethingChanged = useMemo(() => {
    if (createFunnelSucceeded || editDraftFunnelSucceeded) {
      return false;
    }
    return dirty || !isEqual(previousFunnelInfoValues, funnelLevels);
  }, [createFunnelSucceeded, editDraftFunnelSucceeded, dirty, previousFunnelInfoValues, funnelLevels]);

  return (
    <FunnelContext.Provider
      value={{
        funnelInfoValues,
        funnelInfoValuesErrors,
        handleFunnelInfoValuesChange,
        funnelLevels,
        addNewFunnelLevel,
        deleteFunnelLevel,
        updateFunnelLevel,
        submitForm,
        setFieldValue,
        isLoading: isLoadingCreateFunnel || isLoadingEditDraftFunnel,
        generalError,
        validateForm,
        validateMinimumLevelsCount,
        editModeActive,
        isLoadingFunnelToEditData,
        hasSomethingChanged,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
};

const useFunnelContext = () => useContext(FunnelContext);

export { FunnelContextProvider };

export default useFunnelContext;

export function getFunnelLevelIcon(levelsNumber: number, funnelIndex = 0, isSet = false): React.ReactNode {
  const getIcon = () => {
    if (levelsNumber <= 1 || funnelIndex === 0)
      return <EndChainIcon className='rotate-180' color={isSet ? 'white' : '#696969'} />;
    if (levelsNumber - 1 === funnelIndex) return <EndChainIcon color={isSet ? 'white' : '#696969'} />;
    return <MiddleChainIcon className='rotate-180' color={isSet ? 'white' : '#696969'} />;
  };

  return <IconWithBackground className={isSet ? '!bg-green-pure' : '!bg-white'} icon={() => getIcon()} />;
}

function getValidationSchema(translate: Function) {
  return yup.object().shape({
    [FormFieldNames.name]: yup.string().required(translate(validationTranslationKeys.required)),
    [FormFieldNames.conversionRate]: yup
      .number()
      .nullable()
      .min(0, translate('funnels.validation.minConversionRate'))
      .max(100, translate('funnels.validation.maxConversionRate')),
  });
}
