import { useFullNameById } from '@va/dashboard/util-hooks';
import { CheckmarkIcon, NavFunnels, SquareIcon } from '@va/icons';
import { useLocale } from '@va/localization';
import { ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { getFormattedDateAndTime } from '@va/util/helpers';
import { useIsHovering } from '@va/util/hooks';
import { useMemo, useRef } from 'react';
import { useFunnelsContext } from '../FunnelsContext';

type NameCellProps = {
  funnelName: string;
  createdAt: number;
  funnelId: string;
  createdBy: string | null;
};

const NameCell: React.FC<NameCellProps> = ({ funnelName, createdAt, funnelId, createdBy }) => {
  const { locale } = useLocale();
  const iconRef = useRef<HTMLDivElement>(null);
  const { toggleFunnelSelection, isFunnelSelected, websiteContributors } = useFunnelsContext();
  const isHoveringOverIcon = useIsHovering(iconRef);

  const funnelCreator = useFullNameById(createdBy, websiteContributors);

  const icon = useMemo(() => {
    if (isFunnelSelected(funnelId)) return <CheckmarkIcon />;
    if (isHoveringOverIcon) return <SquareIcon />;
    return <NavFunnels color='#3C3C3C' />;
  }, [isFunnelSelected, funnelId, isHoveringOverIcon]);

  return (
    <div className='flex items-center justify-start gap-3 w-full'>
      <div
        ref={iconRef}
        className='flex shrink-0 justify-center items-center w-12 h-12 rounded-full bg-white-snow group-hover:bg-white'
        onClick={() => toggleFunnelSelection(funnelId)}
      >
        {icon}
      </div>
      <div className='text-left overflow-hidden'>
        <ParagraphWithTooltip weight={fontWeights.medium} colorClassName='text-gray-charcoal'>
          {funnelName}
        </ParagraphWithTooltip>
        <ParagraphWithTooltip colorClassName='text-gray-devil' size={paragraphSizes.tiny}>
          {`${getFormattedDateAndTime(createdAt, locale)} · ${funnelCreator}`}
        </ParagraphWithTooltip>
      </div>
    </div>
  );
};

export default NameCell;
