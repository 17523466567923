import { MATCHING_TYPES } from '@va/constants';
import { EndChainIcon, MiddleChainIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { IconWithBackground, Paragraph, ParagraphWithTooltip, fontWeights } from '@va/ui/design-system';
import { appHasDarkTheme, getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import { FunnelLevelType, FunnelLevelTypes } from '../../funnel.types';

const LevelsDropdown = ({ levels }: { levels: FunnelLevelType[] }) => {
  const translate = useTranslate();
  const isDarkThemeApplied = appHasDarkTheme();

  return (
    <div className='p-3 overflow-hidden text-left'>
      <ul className='space-y-3 overflow-auto max-w-full max-h-350 scrollbar scrollbar-thumb'>
        {levels.map((level, index) => {
          const matchOptionLabelKey = MATCHING_TYPES.find((option) => option.match === level.match)?.labelKey;
          return (
            <li className='flex items-center gap-3' key={`funnel-level-${index}`}>
              <IconWithBackground
                className={classNames('shrink-0', {
                  'bg-black/20': isDarkThemeApplied,
                  'bg-white/20': !isDarkThemeApplied,
                })}
                icon={() => getFunnelLevelIcon(index, levels.length)}
              />
              <div className='overflow-hidden'>
                <Paragraph weight={fontWeights.medium}>
                  {translate('labels.funnels.level', { count: index + 1 })}:{' '}
                  {level.type === FunnelLevelTypes.URL_PATTERN
                    ? translate(matchOptionLabelKey as string)
                    : translate('labels.website.page')}
                </Paragraph>
                <ParagraphWithTooltip
                  colorClassName={classNames({
                    'text-white-66': !isDarkThemeApplied,
                    '': isDarkThemeApplied,
                  })}
                >
                  {getUrlWithoutProtocolAndTrailingSlash(level.url)}
                </ParagraphWithTooltip>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LevelsDropdown;

function getFunnelLevelIcon(levelIndex: number, levelsNumber: number, color = 'white') {
  if (levelsNumber <= 1 || levelIndex === 0) return <EndChainIcon className='rotate-180' color={color} />;
  if (levelsNumber - 1 === levelIndex) return <EndChainIcon color={color} />;
  return <MiddleChainIcon className='rotate-180' color={color} />;
}
