import { useCallback, useState } from 'react';
import { EditFunnelFormInputNames } from '../edit-funnel/EditFunnelModal';

const useEditFunnelModal = () => {
  const [isEditFunnelModalVisible, setEditFunnelModalVisible] = useState(false);
  const [visibleInputItemsForEdit, setVisibleInputItemsForEdit] = useState<EditFunnelFormInputNames[]>([]);

  const openEditFunnelNameModal = useCallback(() => {
    setEditFunnelModalVisible(true);
    setVisibleInputItemsForEdit([EditFunnelFormInputNames.name]);
  }, []);

  const closeEditFunnelModal = useCallback(() => {
    setEditFunnelModalVisible(false);
  }, []);

  return {
    openEditFunnelNameModal,
    closeEditFunnelModal,
    isEditFunnelModalVisible,
    visibleInputItemsForEdit,
  };
};

export default useEditFunnelModal;
