export type FunnelLevelType = {
  name: string;
  type: number;
  url: string;
  initialUrl?: string;
  match: number;
};

export type FunnelLevelWithIdType = FunnelLevelType & { id: string };

export type FunnelType = {
  conversionRate?: number | undefined | null;
  status: number;
  levels: FunnelLevelType[];
};

export enum FunnelLevelTypes {
  STATIC_PAGE = 0,
  DYNAMIC_PAGE = 1,
  URL_PATTERN = 2,
}

export enum FUNNEL_STATUS_ENUM {
  DRAFT = 0,
  PUBLISHED = 1,
}
