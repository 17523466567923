import { getInstanceId } from '@va/dashboard/selectors/app';
import { useCurrentPeriodFilter, usePreviousPeriodFilter } from '@va/dashboard/util-hooks';
import { get } from '@va/http-client';
import { getPercentageChange } from '@va/util/helpers';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR, { SWRConfiguration } from 'swr';
import { FunnelDetailsDataType } from './funnel-details/funnelDetailsContext';

export const useGetFunnelDetails = (funnelId: string | null | undefined, config?: SWRConfiguration) => {
  const websiteId = useSelector(getInstanceId);
  const filters = useCurrentPeriodFilter();
  const previousFilters = usePreviousPeriodFilter();
  const queryData = useMemo(
    () => ({
      from: filters.from,
      until: filters.until,
      unit: filters.unit,
      previousFrom: previousFilters.from,
      previousUntil: previousFilters.until,
      search: '',
    }),
    [filters, previousFilters],
  );
  const fetcher = useCallback(async (url: string, queryData: Object) => {
    const res = (await get(url, queryData)) as { payload: FunnelDetailsDataType };

    const {
      previousConversionRate,
      conversionRate,
      previousConversion,
      currentConversion,
      visitorCountInTimeRange,
      visitorCountInPreviousTimeRange,
    } = res.payload;

    return {
      ...res.payload,
      conversionRatePercentageChange: getPercentageChange(previousConversionRate, conversionRate),
      conversionPercentageChange: getPercentageChange(previousConversion, currentConversion),
      visitorPercentageChange: getPercentageChange(visitorCountInPreviousTimeRange, visitorCountInTimeRange),
    };
  }, []);

  const swr = useSWR<FunnelDetailsDataType, Error>(
    funnelId ? [`/v2/websites/${websiteId}/funnels/${funnelId}`, queryData] : null,
    fetcher,
    config,
  );

  return {
    ...swr,
    isLoading: swr.isValidating ? !swr.error && isNil(swr.data) : false,
  };
};
