import { createColumnHelper } from '@tanstack/react-table';
import { useLocale, useTranslate } from '@va/localization';
import { FunnelType } from '@va/types/funnel';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { addNumberSeparator } from '@va/util/helpers';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import ActionsCell from './tableCells/ActionsCell';
import { ConversionsCell, ConversionsRateCell, ExpectedConversionCell, VisitorsCell } from './tableCells/FunnelDetails';
import { FunnelStatusCell } from './tableCells/FunnelStatusCell';
import LevelsCell from './tableCells/LevelsCell';
import NameCell from './tableCells/NameCell';

const columnHelper = createColumnHelper<FunnelType>();

export const useFunnelColumns = () => {
  const translate = useTranslate();
  const { locale } = useLocale();

  const columns = useMemo(
    () => [
      columnHelper.group({
        id: 'funnelName',
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tableHeaders.funnelName')} />,
        cell: ({ row }) => {
          const { name, createdAt, key, createdBy } = row.original;
          return <NameCell funnelName={name} createdAt={createdAt} funnelId={key} createdBy={createdBy} />;
        },
        minSize: 400,
      }),
      columnHelper.accessor('status', {
        header: (ctx) => (
          <HeaderCellV8
            text={translate('tableHeaders.funnelStatus')}
            tooltip={translate('all.funnels.table.headerTooltips.funnelStatus')}
            context={ctx}
          />
        ),
        cell: ({ getValue }) => {
          return <FunnelStatusCell status={getValue()} />;
        },
        minSize: 160,
        meta: {
          cellClassName: '!justify-start',
        },
      }),
      columnHelper.accessor('levels', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.funnelLevels')}
            tooltip={translate('all.funnels.table.headerTooltips.funnelLevels')}
          />
        ),
        enableSorting: true,
        minSize: 210,
        meta: {
          cellClassName: '!justify-start',
        },
        cell: ({ getValue }) => {
          return <LevelsCell levels={getValue()} />;
        },
      }),

      columnHelper.accessor('visitorCountInTimeRange', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.funnelVisitors')}
            tooltip={translate('all.funnels.creationHint')}
          />
        ),
        minSize: 80,
        enableSorting: true,
        cell: ({ row }) => {
          const { visitorCountInTimeRange, visitorPercentageChange } = row.original;
          return (
            <VisitorsCell
              visitorsInTimeRange={visitorCountInTimeRange}
              visitorPercentageChange={visitorPercentageChange}
            />
          );
        },
      }),
      columnHelper.accessor('currentConversion', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.funnelConversions')}
            tooltip={translate('all.funnels.table.headerTooltips.funnelConversions')}
          />
        ),
        minSize: 80,
        enableSorting: true,
        cell: ({ row }) => {
          const { currentConversion, conversionPercentageChange } = row.original;
          return (
            <ConversionsCell
              currentConversions={currentConversion}
              conversionPercentageChange={conversionPercentageChange}
            />
          );
        },
      }),
      columnHelper.accessor('conversionRate', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.funnelConversionsRate')}
            tooltip={translate('all.funnels.table.headerTooltips.funnelConversionsRate')}
          />
        ),
        minSize: 80,
        enableSorting: true,
        cell: ({ row }) => {
          const { conversionRate, conversionRatePercentageChange } = row.original;
          return (
            <ConversionsRateCell
              conversionRate={conversionRate}
              conversionRatePercentageChange={conversionRatePercentageChange}
            />
          );
        },
      }),
      columnHelper.accessor('expectedConversionRate', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('tableHeaders.funnelExpectedConversionRate')}
            tooltip={translate('all.funnels.table.headerTooltips.funnelExpectedConversionRate')}
          />
        ),
        minSize: 80,
        enableSorting: true,
        cell: ({ getValue }) => {
          const expectedConversionRate = getValue();

          return (
            <ExpectedConversionCell
              expectedConversionRate={
                isNil(expectedConversionRate)
                  ? 'N/A'
                  : addNumberSeparator(expectedConversionRate, locale, {
                      style: 'percent',
                    })
              }
            />
          );
        },
      }),
      columnHelper.group({
        id: 'funnelActions',
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tableHeaders.funnelActions')} />,
        cell: ({ row }) => {
          const { status, key } = row.original;
          return <ActionsCell funnelStatus={status} funnelId={key} />;
        },
      }),
    ],
    [locale, translate],
  );

  return columns;
};
