import { MinusIcon, NavAddCircle } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, InputMessage, Paragraph, TextInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { TextFilter, TextFilterValue } from './TextFilter';

export const MultiSelectFields = ({
  filter,
  placeholder,
  error,
  setAllValues,
  allValues,
}: {
  filter: TextFilter;
  placeholder?: string;
  error?: string;
  setAllValues: Dispatch<SetStateAction<TextFilterValue[]>>;
  allValues: TextFilterValue[];
}) => {
  const { inputProps, allowMultiSelect } = filter;
  const translate = useTranslate();

  const handleSubValuesChange = useCallback(
    (indexVal: number, value: TextFilterValue) => {
      setAllValues((prev) => {
        const updatedData = prev.map((item, index) => {
          if (index === indexVal) {
            return value;
          }
          return item;
        });
        return updatedData;
      });
    },
    [setAllValues],
  );

  const handleRemove = useCallback(
    (indexVal: number) => {
      setAllValues((prev) => {
        return prev.filter((item, index) => index !== indexVal);
      });
    },
    [setAllValues],
  );

  const handleClearField = useCallback(
    (indexVal: number) => {
      setAllValues((prev) =>
        prev.map((item, index) => {
          if (index === indexVal) {
            return '';
          }
          return item;
        }),
      );
    },
    [setAllValues],
  );

  return (
    <div className='flex flex-col gap-3 max-h-[300px] overflow-y-auto scrollbar scrollbar-thumb'>
      {allValues.map((value, index) => {
        if (index === 0) {
          return (
            <TextInput
              key={index}
              dataTestId='text-filter-input'
              debouncedInput={true}
              value={value}
              clearField={() => {
                handleSubValuesChange(index, '');
              }}
              onChange={(e) => {
                handleSubValuesChange(index, e.target.value);
              }}
              placeholder={placeholder}
              label={inputProps?.label}
              error={error && !value && translate(error)}
            />
          );
        }
        if (!allowMultiSelect) return null;
        return (
          <OrOption
            key={index}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleSubValuesChange(index, e.target.value)}
            error={error && !value && translate(error)}
            onRemove={() => handleRemove(index)}
            clearField={() => handleClearField(index)}
          />
        );
      })}
      {filter.allowMultiSelect && (
        <Button
          icon={(_, color) => <NavAddCircle color={color} />}
          text={translate('label.addNewUrl')}
          color='tertiary'
          className='w-full justify-center flex-shrink-0'
          onClick={() => setAllValues((prev) => [...prev, ''])}
        />
      )}
    </div>
  );
};

export const OrOption = ({
  placeholder,
  onChange,
  value,
  error,
  onRemove,
  clearField,
}: {
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  error?: string;
  onRemove: () => void;
  clearField: () => void;
}) => {
  const translate = useTranslate();
  return (
    <div className='flex flex-col'>
      <div className='flex gap-[2px]'>
        <Paragraph
          weight={fontWeights.medium}
          size={paragraphSizes.tiny}
          className='h-12 rounded-l-xl flex items-center justify-center px-3 bg-white-snow'
        >
          {translate('all.filters.combinationalOperators.or')}
        </Paragraph>
        <TextInput
          dataTestId='text-filter-input'
          debouncedInput={true}
          value={value}
          clearField={clearField}
          onChange={onChange}
          placeholder={placeholder}
          wrapperClassName='w-full'
          heightClass='h-12'
          inputWrapperClassName='!rounded-none'
        />
        <Button
          icon={(_, color) => <MinusIcon color={color} />}
          color='tertiary'
          className='rounded-l-none'
          onClick={onRemove}
        />
      </div>
      {error && <InputMessage error={error} />}
    </div>
  );
};
