import { aaasHistory } from '@va/aaas/shared/router';
import { appHistory as standaloneAppHistory } from '@va/standalone/shared/router';
import { is3asApp, isStandaloneApp, isWebsiteViewerApp, isWixApp, isWixMiniApp } from '@va/util/helpers';
import { appHistory as wixMiniAppHistory } from '@va/wix-mini/shared/router';
import { appHistory as wixAppHistory } from '@va/wix/shared/router';

// @ts-ignore
let appHistory: History<unknown>;

if (isWixApp()) {
  appHistory = wixAppHistory;
} else if (isWixMiniApp()) {
  appHistory = wixMiniAppHistory;
} else if (is3asApp()) {
  appHistory = aaasHistory;
} else if (isWebsiteViewerApp()) {
  appHistory = standaloneAppHistory;
} else if (isStandaloneApp()) {
  appHistory = standaloneAppHistory;
} else {
  appHistory = standaloneAppHistory;
}

export { appHistory };
