import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { useFiltersContext } from '@va/shared/feature-filters';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useCallback, useEffect, useState } from 'react';
import { useDeleteBulkFunnels, useDeleteMultipleFunnels } from './funnels-api-client';

const useDeleteFunnelsConfirmation = ({
  onSuccess,
  setSelectedFunnelsIds,
}: {
  onSuccess: () => void;
  setSelectedFunnelsIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const {
    funnels: { mutate: mutateFunnelsCount },
  } = useFeatureCounterContext();

  const [onDeleteFunnelsConfirmation, setOnDeleteFunnelConfirmation] = useState<(() => void) | undefined>(undefined);
  const { appliedFilterValues } = useFiltersContext();
  const translate = useTranslate();

  const { showErrorNotification, showSuccessNotification } = useAddNotification();

  const {
    isSucceeded: isDeleteFunnelApiSucceeded,
    error: deleteFunnelsApiError,
    execute: executeDeleteFunnels,
  } = useDeleteMultipleFunnels();

  const {
    isSucceeded: isDeleteBulkFunnelsSucceeded,
    error: deleteBulkFunnelsError,
    execute: executeDeleteBulkFunnels,
  } = useDeleteBulkFunnels();

  const deleteMultipleFunnels = useCallback(
    (funnelIds: string[]) => {
      setOnDeleteFunnelConfirmation(() => {
        return async () => {
          await executeDeleteFunnels(funnelIds);
          setSelectedFunnelsIds([]);
        };
      });
    },
    [executeDeleteFunnels, setSelectedFunnelsIds],
  );

  const deleteSingleFunnel = useCallback(
    (funnelId: string) => {
      setOnDeleteFunnelConfirmation(() => {
        return async () => {
          await executeDeleteFunnels([funnelId]);
          setSelectedFunnelsIds((prev) => prev.filter((id) => id !== funnelId));
        };
      });
    },
    [executeDeleteFunnels, setSelectedFunnelsIds],
  );

  const deleteBulk = useCallback(() => {
    setOnDeleteFunnelConfirmation(() => async () => {
      await executeDeleteBulkFunnels(appliedFilterValues);
      setSelectedFunnelsIds([]);
    });
  }, [appliedFilterValues, setSelectedFunnelsIds]);

  const closeDeleteFunnelConfirmationModal = useCallback(() => {
    setOnDeleteFunnelConfirmation(undefined);
  }, []);

  useEffect(() => {
    if (isDeleteFunnelApiSucceeded || isDeleteBulkFunnelsSucceeded) {
      onSuccess();
      mutateFunnelsCount();
      showSuccessNotification(2000, translate('funnels.deleteSuccess'));
    }
  }, [
    isDeleteFunnelApiSucceeded,
    isDeleteBulkFunnelsSucceeded,
    onSuccess,
    showSuccessNotification,
    translate,
    mutateFunnelsCount,
  ]);

  useEffect(() => {
    if (deleteFunnelsApiError || deleteBulkFunnelsError) {
      showErrorNotification();
    }
  }, [deleteFunnelsApiError, deleteBulkFunnelsError, showErrorNotification]);

  return {
    deleteMultipleFunnels,
    deleteSingleFunnel,
    onDeleteFunnelsConfirmation,
    closeDeleteFunnelConfirmationModal,
    deleteBulk,
  };
};

export default useDeleteFunnelsConfirmation;
