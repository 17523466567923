import { PayloadAction } from '@reduxjs/toolkit';
import { getIntpUpgradeMessage, selectAllowUpgrade } from '@va/aaas/shared/selectors';
import { IntpUpgradeMessage } from '@va/aaas/shared/types';
import { WEBSITE_SUCCEEDED } from '@va/dashboard/actions/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { appHistory } from '@va/shared/router';
import { isWixClaimed } from '@va/standalone/shared/selectors';
import { getViewOnWixUrl, is3asApp, isWixApp, isWixMiniApp } from '@va/util/helpers';
import { openBillingPage } from '@va/wix/shared/helpers';
import { getMetaSiteId } from '@va/wix/shared/selectors';
import { put, select, take } from 'redux-saga/effects';
import { openPricingPage } from '@va/wix-mini/shared/helpers';

export function* startUpgradeProcessSaga(action: PayloadAction<{ websiteId: string }>): Generator<any> {
  if (is3asApp()) {
    const allowUpgrade = yield select(selectAllowUpgrade);
    if (!allowUpgrade) return;

    const messageData = (yield select(getIntpUpgradeMessage)) as IntpUpgradeMessage;

    window.parent.postMessage(
      {
        type: 'UPGRADE_BUTTON_CLICKED',
        data: messageData,
      },
      '*',
    );
    return;
  }

  if (isWixApp()) {
    openBillingPage();
    return;
  }

  if (isWixMiniApp()) {
    openPricingPage();
    return;
  }

  const { websiteId } = action.payload ?? {};
  const currentInstanceId = yield select(getInstanceId);

  if (websiteId && currentInstanceId !== websiteId) {
    yield put(setActiveWebsite(websiteId));
    yield take(WEBSITE_SUCCEEDED);
  }

  const updatedInstanceId = yield select(getInstanceId);
  const wixClaimed = yield select(isWixClaimed);

  // On standalone for wix website, redirect to our app in wix
  if (wixClaimed) {
    const metaSiteId = (yield select(getMetaSiteId)) as string;
    const wixUrl = getViewOnWixUrl(metaSiteId);
    window.open(wixUrl, '_blank');
    return;
  }

  appHistory.push(`/website/${updatedInstanceId}/upgrade`);
}
