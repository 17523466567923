import { apiRequestResponseMsg, pathName } from '@va/constants';
import { WEBSITE_SUCCEEDED } from '@va/dashboard/actions/api';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { isServerError } from '@va/http-client';
import * as Actions from '@va/standalone/shared/actions';
import * as Types from '@va/standalone/shared/actions';
import * as Api from '@va/standalone/shared/api-client';
import {
  addCredentialsToLocalStorage,
  getLastWebsiteId,
  isUserLoggedInWithCustomUrl,
  removeCredentialsFromLocalStorage,
} from '@va/standalone/shared/helpers';
import { appHistory } from '@va/shared/router';
import * as Selectors from '@va/standalone/shared/selectors';
import { getAgencyUi } from '@va/standalone/shared/selectors';
import { formatQueryParamsToRedirectURL, getParameterByName } from '@va/util/helpers';
import moment from 'moment';
import { all, call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import { acceptContributorRoleWithToken } from 'standalone/apiClient/Account';
import { resetVisitsLimitNotificationMonthlyFlag } from 'standalone/sagas/app';

export function* watchers() {
  yield all([
    takeLatest(Types.standalone.Api.LOGIN_REQUEST, login),
    takeLatest(Types.standalone.Api.CHECK_LOGIN_REQUEST, checkLogin),
    takeLatest(Types.standalone.Api.BACKGROUND_LOGIN_REQUEST, backgroundLogin),
    takeLatest(Types.standalone.Api.CREATE_USER_REQUEST, createUser),
    takeLatest(Types.standalone.Api.ACTIVATE_USER_REQUEST, activateUser),
    takeLatest(Types.standalone.Api.CREATE_APPSUMO_USER_REQUEST, createAppsumoUser),
    takeLatest(Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_REQUEST, generateImpersonateToken),
    takeLatest(Types.standalone.Api.FORGOT_PASSWORD_REQUEST, forgotPassword),
    takeLatest(Types.standalone.Api.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(Types.standalone.Api.RESEND_REGISTRATION_CONFIRMATION_REQUEST, resendRegisterConfirmation),
    takeLatest(Types.standalone.Api.REGISTER_CONTRIBUTOR_REQUEST, registerContributor),
  ]);
}

export function* createUser(action) {
  try {
    const requestData = {
      email: action.data.email.trim(),
      password: action.data.password,
      platform: action.data.platform,
      firstName: action.data.firstName?.trim(),
      lastName: action.data.lastName?.trim(),
      companyName: action.data.companyName?.trim(),
      privacyTermsAccepted: action.data.termsAndDpaAccepted || false,
      dpaAccepted: action.data.termsAndDpaAccepted || false,
      businessConditionAccepted: action.data.termsAndDpaAccepted || false,
      newsletterSubscribed: action.data.newsletterSubscribed || false,
    };

    const data = yield call(Api.createUser, requestData);

    removeCredentialsFromLocalStorage();
    yield put(Actions.standalone.Api.createUserSucceeded(data));
  } catch (error) {
    yield put(Actions.standalone.Api.createUserFailed(error));
  }
}

export function* createAppsumoUser(action) {
  try {
    const requestData = {
      email: action.data.get('email').trim(),
      password: action.data.get('password'),
      firstName: action.data.get('firstName', '').trim(),
      lastName: action.data.get('lastName', '').trim(),
      companyName: action.data.get('companyName', '').trim(),
      privacyTermsAccepted: action.data.get('termsAndDpaAccepted', false),
      dpaAccepted: action.data.get('termsAndDpaAccepted', false),
      businessConditionAccepted: action.data.get('termsAndDpaAccepted', false),
      newsletterSubscribed: action.data.get('newsletterSubscribed', false),
    };

    const data = yield call(Api.createUser, requestData);

    let response = yield put(Actions.standalone.Api.createAppsumoUserSucceeded(data));
    yield put(Actions.standalone.App.setAppsumoStep('loggedIn'));
    yield put(Actions.standalone.App.setAppsumoUserId(response.data.id));
  } catch (error) {
    yield put(Actions.standalone.Api.createAppsumoUserFailed(error));
  }
}

export function* activateUser(action) {
  try {
    yield call(Api.activateUser, {
      confirmationToken: action.token,
    });

    yield put(Actions.standalone.Api.activateUserSucceeded());
    appHistory.push({ pathname: '/login' });
  } catch (error) {
    yield put(Actions.standalone.Api.activateUserFailed(error));
  }
}

export function* login(action) {
  try {
    const requestData = {
      email: action.data.email.trim(),
      password: action.data.password,
    };

    if (isUserLoggedInWithCustomUrl()) {
      const agencyUi = yield select(getAgencyUi);
      requestData.agencyId = agencyUi.agencyId;
    }

    const response = yield call(Api.login, requestData);

    addCredentialsToLocalStorage(response);

    yield put(Actions.standalone.Api.loginSucceeded(response));
    yield put(Actions.standalone.Ui.rememberEmailForLogin(requestData.email));
    let pushTo = formatQueryParamsToRedirectURL();

    if (pushTo !== 'null' && pushTo?.includes('/website/')) {
      window.skipTrackingCodeRedirect = true;
      appHistory.push(pushTo, {}, true);
    }

    yield put(Actions.standalone.App.initialize());

    const websiteAction = yield take([WEBSITE_SUCCEEDED]);
    if (websiteAction.type === WEBSITE_SUCCEEDED) {
      yield spawn(resetVisitsLimitNotificationMonthlyFlag); //Reset monthly visits limit flag after new login.
    }

    //Wait for Initialize process to end in order to know where to redirect.
    yield take(Types.standalone.App.INITIALIZE_SUCCEEDED);

    const appsumoMode = yield select(Selectors.standalone.App.getAppsumoStep);

    const hasFinishedOnboarding = yield select(Selectors.userHasFinishedOnboarding);
    const lastStepCompleted = yield select(Selectors.getLastOnboardingStep);
    if (!hasFinishedOnboarding && lastStepCompleted !== undefined) {
      yield put(Actions.standalone.App.setOnboardingStep(lastStepCompleted + 1));
      pushTo = '/activate';
    }

    const defaultRedirectRoute = getLastWebsiteId() ? '/' : pathName.manageWebsites;
    if (pushTo === 'null' || pushTo === null) {
      pushTo = defaultRedirectRoute;
    }

    if (appsumoMode === 'register') {
      appHistory.push('/appsumo');
    } else {
      appHistory.push(pushTo, {}, true, true);
    }
  } catch (error) {
    yield put(Actions.standalone.Ui.rememberEmailForLogin(action.data.email.trim()));
    if (isServerError(Number(error.message))) {
      yield put(
        Actions.standalone.Api.loginFailed({
          ...error,
          message: apiRequestResponseMsg.INTERNAL_SERVER_ERROR,
        }),
      );
      return;
    }
    yield put(Actions.standalone.Api.loginFailed(error));
  }
}

export function* generateImpersonateToken(action) {
  try {
    const requestData = {
      email: action.data.username.trim(),
      password: action.data.password,
      impersonate: action.data.impersonateEmail,
    };

    const response = yield call(Api.generateImpersonateToken, requestData);

    addCredentialsToLocalStorage(response, action.type);
    const now = moment().unix();
    const credentials = {
      userId: response.userId,
      token: response.token,
      refreshToken: response.refreshToken,
      impersonateToken: true,
      //auth token is valid for an hour
      tokenExpiry: now + 3600,
      //refresh token is valid for 24 hours
      refreshTokenExpiry: now + 86400,
      instanceId: response.impersonate,
    };

    yield put(Actions.standalone.Api.generateImpersonateTokenSucceeded(response));

    appHistory.push(`/impersonate`, { impersonateData: credentials });
  } catch (error) {
    yield put(Actions.standalone.Api.generateImpersonateTokenFailed(error));
  }
}

export function* checkLogin(action) {
  try {
    const user = yield select(getAccountInformation);

    const requestData = {
      email: user.email,
      password: action.data.password || action.data.get('password'),
    };

    if (isUserLoggedInWithCustomUrl()) {
      const agencyUi = yield select(getAgencyUi);
      requestData.agencyId = agencyUi.agencyId;
    }

    yield call(Api.login, requestData);

    yield put(Actions.standalone.Api.checkLoginSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.checkLoginFailed(error));
  }
}

export function* backgroundLogin(action) {
  try {
    const requestData = {
      email: action.data.email,
      password: action.data.password,
    };

    if (isUserLoggedInWithCustomUrl()) {
      const agencyUi = yield select(getAgencyUi);
      requestData.agencyId = agencyUi.agencyId;
    }

    const response = yield call(Api.login, requestData);

    addCredentialsToLocalStorage(response);

    yield put(Actions.standalone.Api.backgroundLoginSucceeded(response));
    yield put(Actions.standalone.Ui.rememberEmailForLogin(requestData.email));
    appHistory.push('/my-account/websites');

    yield put(Actions.standalone.App.initialize());
  } catch (error) {
    yield put(Actions.standalone.Ui.rememberEmailForLogin(action.data.get('email')));
    yield put(Actions.standalone.Api.backgroundLoginFailed(error));
  }
}

export function* forgotPassword(action) {
  try {
    const requestData = {
      email: action.data.email.trim(),
      platform: action.data.platform,
    };

    const data = yield call(Api.forgotPassword, requestData);

    yield put(Actions.standalone.Api.forgotPasswordSucceeded(data));
  } catch (error) {
    if (isServerError(Number(error.message))) {
      yield put(
        Actions.standalone.Api.forgotPasswordFailed({
          ...error,
          message: apiRequestResponseMsg.INTERNAL_SERVER_ERROR,
        }),
      );
      return;
    }
    yield put(Actions.standalone.Api.forgotPasswordFailed(error));
  }
}

export function* resetPassword(action) {
  try {
    const requestData = {
      resetPasswordToken: action.token,
      password: action.data.password,
    };

    yield call(Api.resetPassword, requestData);

    yield put(Actions.standalone.Api.resetPasswordSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.resetPasswordFailed(error));
  }
}

export function* resendRegisterConfirmation(action) {
  try {
    const user = yield select(Selectors.standalone.Api.getUser);
    const requestData = {
      email: user.get('email') || action.email,
    };

    yield call(Api.resendRegisterConfirmation, requestData);

    yield put(Actions.standalone.Api.resendRegistrationConfirmationSucceeded());
  } catch (error) {
    yield put(Actions.standalone.Api.resendRegistrationConfirmationFailed(error));
  }
}

export function* registerContributor(action) {
  try {
    const token = getParameterByName('token');
    const type = getParameterByName('type');

    const requestData = {
      email: action.data.email.trim(),
      password: action.data.password,
      firstName: action.data.firstName.trim(),
      lastName: action.data.lastName.trim(),
      privacyTermsAccepted: action.data.termsAndDpaAccepted || false,
      dpaAccepted: action.data.termsAndDpaAccepted || false,
      businessConditionAccepted: action.data.termsAndDpaAccepted || false,
      newsletterSubscribed: action.data.newsletterSubscribed || false,
      confirmationToken: token,
    };

    if (isUserLoggedInWithCustomUrl()) {
      requestData.companyId = getParameterByName('companyId');
    }

    const data = yield call(Api.registerContributor, requestData);

    yield put(Actions.standalone.Api.registerContributorSucceeded(data));

    if (token && type) {
      yield call(acceptContributorRoleWithToken, { confirmationToken: token, type });
    }

    const loginInfo = {
      email: action.data.email,
      password: action.data.password,
    };
    yield put(Actions.standalone.Api.backgroundLogin(loginInfo));
  } catch (error) {
    yield put(Actions.standalone.Api.registerContributorFailed(error));
  }
}
