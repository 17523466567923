import { Table, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useGetWebsiteContributors } from '@va/dashboard/api-client/contributors.api';
import { ContributorsMappedDataType } from '@va/types/contributors';
import { FunnelType, funnelsSelectOptionsEnum } from '@va/types/funnel';
import {
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useGetFunnels } from './funnels-api-client';
import useActivateFunnel from './useActivateFunnel';
import useDeleteFunnelsConfirmation from './useDeleteFunnelsConfirmation';
import useEditFunnelModal from './useEditFunnelModal';
import { useFunnelColumns } from './useFunnelColumns';

type FunnelsContextTypes = {
  isGetFunnelsLoading: boolean;
  isFunnelSelected: (funnelId: string) => boolean;
  mutateFunnels: () => void;
  toggleFunnelSelection: (funnelId: string) => void;
  selectedFunnelsIds: string[];
  websiteContributors: ContributorsMappedDataType | undefined;
  funnels: FunnelType[];
  handleActivateFunnel: (funnelId: string) => void;
  selectAllFunnels: () => void;
  clearAllSelectedFunnels: () => void;
  chooseFunnelIdForEdit: (funnelId: string | undefined) => void;
  chosenFunnelIdForEdit: string | undefined;
  editFunnelModal: ReturnType<typeof useEditFunnelModal>;
  deleteFunnelsModal: ReturnType<typeof useDeleteFunnelsConfirmation>;
  selectedBulkOption: funnelsSelectOptionsEnum | null;
  setSelectedBulkOption: (option: funnelsSelectOptionsEnum | null) => void;
  table: Table<FunnelType>;
};

const FunnelContext = createContext({} as FunnelsContextTypes);

const FunnelContextProvider = forwardRef(({ children }: PropsWithChildren<{}>, ref) => {
  const [selectedFunnelsIds, setSelectedFunnelsIds] = useState<string[]>([]);
  const [chosenFunnelIdForEdit, setChosenFunnelIdForEdit] = useState<string | undefined>(undefined);

  const { isLoading: isGetFunnelsLoading, data: funnels, mutate: mutateFunnels } = useGetFunnels();
  const { data: websiteContributors } = useGetWebsiteContributors();
  const [selectedBulkOption, setSelectedBulkOption] = useState<funnelsSelectOptionsEnum | null>(null);

  const deleteFunnelsModal = useDeleteFunnelsConfirmation({
    onSuccess: mutateFunnels,
    setSelectedFunnelsIds,
  });

  const editFunnelModal = useEditFunnelModal();

  const rowCount = useMemo(() => funnels?.length ?? 0, [funnels]);

  const columns = useFunnelColumns();

  const table = useReactTable<FunnelType>({
    data: funnels ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      minSize: 210,
      enableSorting: false,
      enableGrouping: false,
      enableMultiSort: false,
      enableColumnFilter: false,
    },
    rowCount: rowCount,
    enableMultiRowSelection: false,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 12,
      },
    },
  });

  const chooseFunnelIdForEdit = useCallback((funnelId: string | undefined) => {
    setChosenFunnelIdForEdit(funnelId);
  }, []);

  const clearAllSelectedFunnels = useCallback(() => {
    setSelectedFunnelsIds([]);
  }, []);

  useImperativeHandle(ref, () => ({
    refreshFunnelsData: mutateFunnels,
  }));

  const handleActivateFunnel = useActivateFunnel({ onSuccess: mutateFunnels });

  const isFunnelSelected = useCallback(
    (funnelId: string) => {
      return selectedFunnelsIds.includes(funnelId);
    },
    [selectedFunnelsIds],
  );

  const toggleFunnelSelection = useCallback(
    (funnelId: string) => {
      if (isFunnelSelected(funnelId)) {
        setSelectedFunnelsIds((prev) => prev.filter((crtFunnelId) => funnelId !== crtFunnelId));
      } else {
        setSelectedFunnelsIds((prev) => [...prev, funnelId]);
      }
      setSelectedBulkOption(null);
    },
    [isFunnelSelected],
  );

  const selectAllFunnels = useCallback(() => {
    funnels?.length && setSelectedFunnelsIds(funnels?.map((funnel) => funnel.key));
  }, [funnels]);

  useEffect(() => {
    clearAllSelectedFunnels();
    setSelectedBulkOption(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnels]);

  return (
    <FunnelContext.Provider
      value={{
        isGetFunnelsLoading,
        deleteFunnelsModal,
        isFunnelSelected,
        mutateFunnels,
        toggleFunnelSelection,
        selectedFunnelsIds,
        websiteContributors,
        funnels: funnels ?? [],
        handleActivateFunnel,
        selectAllFunnels,
        clearAllSelectedFunnels,
        editFunnelModal,
        chooseFunnelIdForEdit,
        chosenFunnelIdForEdit,
        selectedBulkOption,
        setSelectedBulkOption,
        table,
      }}
    >
      {children}
    </FunnelContext.Provider>
  );
});

export default FunnelContextProvider;

export const useFunnelsContext = () => useContext(FunnelContext);
