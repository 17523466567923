import { getAuthenticationInstance, getInstanceId } from '@va/dashboard/selectors/app';
import { useCurrentPeriodFilter, usePreviousPeriodFilter } from '@va/dashboard/util-hooks';
import { patch, remove } from '@va/http-client';
import { useFiltersContext } from '@va/shared/feature-filters';
import { FunnelType } from '@va/types/funnel';
import { getPercentageChange, isStandaloneApp, toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useCopyToClipboard, useFetchData } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export type FunnelsListType = FunnelType[];

export const useGetFunnels = () => {
  const { websiteId, ...filters } = useCurrentPeriodFilter();
  const previousFilters = usePreviousPeriodFilter();
  const { appliedFilterValues } = useFiltersContext();

  const queryData = useMemo(
    () => ({
      from: filters.from,
      until: filters.until,
      unit: filters.unit,
      previousFrom: previousFilters.from,
      previousUntil: previousFilters.until,
      ...appliedFilterValues,
    }),
    [filters, previousFilters],
  );

  const mapperFn = useCallback((funnels: FunnelsListType) => {
    return funnels.map((funnel) => {
      const {
        previousConversionRate,
        conversionRate,
        previousConversion,
        currentConversion,
        visitorCountInTimeRange,
        visitorCountInPreviousTimeRange,
      } = funnel;
      return {
        ...funnel,
        conversionRatePercentageChange: getPercentageChange(previousConversionRate, conversionRate),
        conversionPercentageChange: getPercentageChange(previousConversion, currentConversion),
        visitorPercentageChange: getPercentageChange(visitorCountInPreviousTimeRange, visitorCountInTimeRange),
        levelsCount: funnel.levels.length ?? 0,
      };
    });
  }, []);

  return useFetchData<FunnelsListType>(
    `/v2/websites/${websiteId}/funnels?${toQueryString(queryData)}`,
    { revalidateOnMount: true, revalidateIfStale: true },
    (response) => mapperFn(response?.payload ?? []),
  );
};

export const useDeleteFunnel = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = (funnelId: string) =>
    remove(`/v2/websites/${websiteId}/funnels`, undefined, {
      funnelIds: [funnelId],
    });
  return useAsyncFunction<typeof asyncFunc>(asyncFunc, { immediate: false });
};

export const useDeleteMultipleFunnels = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = (funnelIds: string[]) => remove(`/v2/websites/${websiteId}/funnels`, {}, { funnelIds });
  return useAsyncFunction<typeof asyncFunc>(asyncFunc, { immediate: false });
};

export const useDeleteBulkFunnels = () => {
  const { websiteId, ...filters } = useCurrentPeriodFilter();
  const previousFilters = usePreviousPeriodFilter();

  const asyncFunc = (appliedFilterValues: Record<string, unknown>) => {
    const payload = {
      filter: {
        from: filters.from,
        until: filters.until,
        unit: filters.unit,
        previousFrom: previousFilters.from,
        previousUntil: previousFilters.until,
        ...appliedFilterValues,
      },
    };
    return remove(`/v2/websites/${websiteId}/funnels/bulk`, {}, payload);
  };

  return useAsyncFunction<typeof asyncFunc>(asyncFunc, { immediate: false });
};

export const useEditFunnel = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = (funnelId: string, data: Record<string, unknown>) =>
    patch(`/websites/${websiteId}/funnels/${funnelId}`, {}, data);

  return useAsyncFunction<typeof asyncFunc>(asyncFunc, { immediate: false });
};

export const useGetFunnelLink = (funnelId: string) => {
  const { success, onClickCopy } = useCopyToClipboard();
  const websiteId = useSelector(getInstanceId);
  const origin = window.location.origin;
  const authInstance = useSelector(getAuthenticationInstance);

  const link = useMemo(() => {
    if (isStandaloneApp()) {
      return `${origin}/website/${websiteId}/behaviour/funnels/${funnelId}`;
    }
    return `${origin}/?instance=${authInstance}`;
  }, [origin, websiteId, funnelId, authInstance]);

  const copyLink = useCallback(() => {
    onClickCopy(link);
  }, [link, onClickCopy]);

  return { copyLink, success };
};
