import { getInstanceId } from '@va/dashboard/selectors/app';
import { useAsyncFunction } from '@va/util/hooks';
import { post } from '@va/http-client';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FunnelType } from '../funnel.types';

const useCreateFunnel = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = useCallback(
    (data: FunnelType) => {
      return post(`/websites/${websiteId}/funnels`, {}, data);
    },
    [websiteId],
  );

  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export default useCreateFunnel;
