import { IconProps } from '.';

export const PagesIcon = ({ className, color = 'var(--color-primary)' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.34961 3.75C1.34961 2.50736 2.35697 1.5 3.59961 1.5H9.59961C10.8423 1.5 11.8496 2.50736 11.8496 3.75V14.25C11.8496 15.4926 10.8422 16.5 9.59961 16.5H3.59961C2.35697 16.5 1.34961 15.4926 1.34961 14.25V3.75Z'
        stroke={color}
        strokeWidth='1.5'
      />
      <g opacity='0.75'>
        <path d='M14.0996 4.5V13.5' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      </g>
      <g opacity='0.5'>
        <path d='M16.3496 6V12' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
      </g>
    </svg>
  );
};
