import { TEST_IDS, userActions } from '@va/constants';
import { LinkIcon, MinusIcon, MoreOptionsIcon, PencilEditIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { SelectActionDropdown, SelectActionDropdownOption } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFunnelsContext } from '../FunnelsContext';
import { useGetFunnelLink } from '../funnels-api-client';

const FunnelOperationsButton = ({ funnelId }: { funnelId: string }) => {
  const translate = useTranslate();
  const hasEditFunnelPermission = useSelector((state) => hasPermission(state, userActions.Edit));
  const hasDeleteFunnelPermission = useSelector((state) => hasPermission(state, userActions.Delete));
  const { copyLink, success: copyLinkSuccess } = useGetFunnelLink(funnelId);
  const {
    chooseFunnelIdForEdit,
    editFunnelModal: { openEditFunnelNameModal },
    deleteFunnelsModal: { deleteSingleFunnel },
  } = useFunnelsContext();

  const options = useMemo<SelectActionDropdownOption[]>(() => {
    return [
      {
        icon: () => <PencilEditIcon color='#696969' />,
        text: translate('funnel.action.editFunnel'),
        onClick: () => {
          chooseFunnelIdForEdit(funnelId);
          openEditFunnelNameModal();
        },
        tooltip: !hasEditFunnelPermission ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasEditFunnelPermission,
        'data-testid': TEST_IDS.generic.funnels.editNameBtn,
      },
      {
        icon: () => <LinkIcon color='#696969' />,
        text: translate('button.copyFunnelLink'),
        onClick: () => copyLink(),
        hideDropdownOnClick: false,
        tooltip: translate('card.tooltip.copied'),
        tooltipProps: {
          visible: copyLinkSuccess,
        },
        'data-testid': TEST_IDS.generic.funnels.copyLinkBtn,
      },
      {
        icon: () => <MinusIcon color={!hasEditFunnelPermission ? '#696969' : '#EA2A0C'} />,
        text: translate('funnel.actions.delete'),
        textColorClass: 'text-red-pure',
        onClick: () => {
          deleteSingleFunnel(funnelId);
        },
        tooltip: !hasDeleteFunnelPermission ? translate('tooltips.contributorRole') : undefined,
        disabled: !hasDeleteFunnelPermission,
        'data-testid': TEST_IDS.generic.funnels.deleteBtn,
      },
    ];
  }, [
    chooseFunnelIdForEdit,
    copyLink,
    copyLinkSuccess,
    deleteSingleFunnel,
    funnelId,
    hasDeleteFunnelPermission,
    hasEditFunnelPermission,
    openEditFunnelNameModal,
    translate,
  ]);

  return (
    <SelectActionDropdown
      wrapperClassName='group'
      showRightSideIcon={false}
      options={options}
      buttonColor='secondary'
      icon={(_, color) => <MoreOptionsIcon color={color} />}
      tooltipOptions={{ placement: 'left-start', zIndex: 10 }}
      data-testid-btn={TEST_IDS.generic.funnels.moreActionsBtn}
    />
  );
};

export default FunnelOperationsButton;
