import {
  CommonFilterParams,
  DefaultFilterOptionType,
  Filter,
  FilterLogicalOperators,
  FilterOperators,
  useOptionsHook,
} from '@va/types/filters';

import { MultiSelectFilterInput } from './MultiSelectFilterInput';
import { MultiSelectFilterInputApplied } from './MultiSelectFilterInputApplied';

export type MultiSelectFilterValue = number | string;

export type MultiSelectFilterProps = {
  useOptions: useOptionsHook;
  allOption?: DefaultFilterOptionType;
  showSearchInput?: boolean;
  searchPlaceholder?: string;
};

type MultiSelectFilterParams = CommonFilterParams<MultiSelectFilterValue, MultiSelectFilterProps> & {
  optionsLogicalOperator?: FilterLogicalOperators;
};

export class MultiSelectFilter extends Filter<MultiSelectFilterValue, MultiSelectFilterProps> {
  optionsLogicalOperator?: FilterLogicalOperators;

  constructor({ optionsLogicalOperator = FilterLogicalOperators.or, ...params }: MultiSelectFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.is,
      input: MultiSelectFilterInput,
      appliedInput: MultiSelectFilterInputApplied,
      multipleValuesAllowed: true,
    });

    this.optionsLogicalOperator = optionsLogicalOperator;
  }
}
