import { splitPath } from '@va/standalone/shared/router';
import { getParameterByName, toQueryString } from '@va/util/helpers';
import { createBrowserHistory, LocationDescriptor, LocationState, Path } from 'history';

const history = createBrowserHistory();
const initialPushFn = history.push.bind({});
const initialReplaceFn = history.replace.bind({});

const handlePush = (initialFn: typeof initialPushFn, path: LocationDescriptor<unknown>, state: LocationState) => {
  const { pathname, queryParams, anchor } = splitPath(path);

  const params: Record<string, any> = {};

  try {
    // Parsing the new query parameters, depending the situation the instance parameter could also be among them
    const url = new URL(window.location.origin + queryParams);

    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });
  } catch (error) {
    console.error(error);
  }

  // Trying one more time to manualy find the instance parameter if it was not added earlier
  if (!params['instance']) {
    params['instance'] = getParameterByName('instance');
  }

  const newPath = pathname + `?${toQueryString(params)}` + anchor;

  return initialFn(newPath, state);
};

const handleReplace = (
  initialFn: typeof initialReplaceFn,
  path: Path | LocationDescriptor<unknown>,
  state: LocationState,
) => {
  const queryString = window.location.search;

  if (queryString) {
    const { pathname } = splitPath(path);
    return initialFn(pathname + queryString, state);
  }

  return initialFn(path, state);
};
history.push = (path, state) => handlePush(initialPushFn, path, state);
history.replace = (path, state) => handleReplace(initialReplaceFn, path, state);

export { history as appHistory };
