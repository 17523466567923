import { apiStatus, Z_INDEX } from '@va/constants';
import { CommunityButton } from '@va/dashboard/components';
import { isWhiteLabelEnabled } from '@va/dashboard/selectors/core';
import { isSidebarOpen, isTabletDevice } from '@va/dashboard/selectors/ui';
import { navigationActions } from '@va/dashboard/slices';
import { getAgencyLogoUrl } from '@va/http-client';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { WebsiteSelectorV2 } from '@va/standalone/shared/feature-website-selector';
import {
  getLastWebsiteId,
  getUserId,
  isAccountSettingsPageSelected,
  isUserLoggedInWithCustomUrl,
} from '@va/standalone/shared/helpers';
import { getApiRequest, getApiRequestStatus } from '@va/standalone/shared/selectors';
import { ButtonLoader, CloseIconWithBorder, HamburgerIcon, LogoApp } from '@va/svg-visa-icons';
import { FeatureTourButtonContainer } from '@va/ui/feature-tour';
import { isStandaloneApp } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AccountSettingsButton, LogoutButton, UserInfo, UserMenu, useUserInformation } from './userMenu';

export const TOP_BAR_TITLE_WRAPPER_ID = 'top-bar-title';

function AgencyLogo() {
  const agencyUiRequestName = 'getAgencyUi';
  const agencyUiStatus = useSelector((state) => getApiRequestStatus(state, agencyUiRequestName));
  const agencyUi = useSelector((state) => getApiRequest(state, agencyUiRequestName));
  return agencyUiStatus === apiStatus.IN_PROGRESS ? (
    <ButtonLoader color='var(--color-primary)' />
  ) : (
    <Link to={'/'} className='cursor-pointer'>
      {window.logoId && (
        <img
          className='max-w-[250px] max-h-[30px] w-full h-full cursor-pointer'
          alt={agencyUi.getIn(['data', 'logoAlt'])}
          src={getAgencyLogoUrl(getUserId() || '', agencyUi.getIn(['data', 'id']))}
        />
      )}
    </Link>
  );
}

function TopBarLogo() {
  const whiteLabelEnabled = useSelector(isWhiteLabelEnabled);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  return (
    <div id='app-logo' className='hidden lg:flex absolute justify-center left-1/2 transform -translate-x-1/2 z-1'>
      {(whiteLabelEnabled && !isAccountSettingsPageSelected(pathname)) || isUserLoggedInWithCustomUrl() ? (
        <AgencyLogo />
      ) : (
        <Link
          to={'/'}
          className='cursor-pointer'
          onClick={() => {
            if (!isAccountSettingsPageSelected()) return;
            dispatch(setActiveWebsite(getLastWebsiteId(), false, false));
          }}
        >
          <LogoApp />
        </Link>
      )}
    </div>
  );
}

export type TopBarProps = { hasBackButton: boolean };
export const useSidebarToggleEffect = (hasBackButton: boolean, isSidebarOpen: boolean, onToggleSidebar: () => void) => {
  const isTablet = useSelector(isTabletDevice);

  useEffect(() => {
    if (!hasBackButton) return;

    if (isSidebarOpen) {
      onToggleSidebar();
    }
  }, [isTablet, hasBackButton]);
};

export const topBarClassNames =
  'z-999 h-[70px] px-2 lg:pl-18px lg:pr-6px backdrop-filter backdrop-blur-md flex flex-row justify-between items-center flex-nowrap border-b border-gray-mercury fixed top-0 w-full';

export const TopBar: FC<TopBarProps> = ({ hasBackButton }) => {
  const { userInfo } = useUserInformation();
  const isTablet = useSelector(isTabletDevice);
  const isLeftSideNavOpen = useSelector(isSidebarOpen);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const toggleSidebar = useCallback(() => {
    dispatch(navigationActions.toggleSidebar());
  }, [dispatch]);

  useSidebarToggleEffect(hasBackButton, isLeftSideNavOpen, toggleSidebar);

  const isAccountSettingsPage = isAccountSettingsPageSelected(pathname);

  return (
    <div style={{ zIndex: Z_INDEX.topBar }} id='top-bar' className={classNames(topBarClassNames)}>
      <div className='flex lg:hidden flex-row flex-nowrap items-center overflow-hidden'>
        {!hasBackButton && (
          <div
            id='top-bar-icon-wrapper'
            onClick={toggleSidebar}
            className='w-6 h-6 flex items-center justify-center cursor-pointer'
          >
            {isLeftSideNavOpen ? <CloseIconWithBorder radius={12} color='#000' /> : <HamburgerIcon />}
          </div>
        )}
        <div id={TOP_BAR_TITLE_WRAPPER_ID} className={classNames('min-w-0', { 'pl-18': !hasBackButton })} />
      </div>
      {(!hasBackButton || !isTablet) && (
        <>
          {isStandaloneApp() && (
            <div className='hidden lg:block'>
              <WebsiteSelectorV2 />
            </div>
          )}
          {!isAccountSettingsPage && <TopBarLogo />}
          <div className='flex items-center gap-3'>
            {!isTablet && <CommunityButton isInHeader className='max-w-[292px]' />}
            <FeatureTourButtonContainer />
            <UserMenu
              renderAccountSettingsButton={(onClose) => <AccountSettingsButton onClose={onClose} />}
              renderUserInfo={(onClose) => <UserInfo userInformation={userInfo} onClose={onClose} hasLinkAccesible />}
              logoutButton={<LogoutButton />}
            />
          </div>
        </>
      )}
    </div>
  );
};

export function TitlePortal({ children }: { children: React.ReactNode }) {
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null);

  const { width } = useWindowDimensions();

  useEffect(() => {
    const topBarWrapper = document.getElementById(TOP_BAR_TITLE_WRAPPER_ID);
    setWrapper(topBarWrapper);
  }, [width]);

  if (!wrapper) {
    return null;
  }

  return createPortal(children, wrapper);
}
