import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

import { apiStatus } from '@va/constants';
import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getLocale, isModalOpen } from '@va/dashboard/selectors/ui';
import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import * as Actions from '@va/standalone/shared/actions';
import { appHistory } from '@va/shared/router';
import * as Selectors from '@va/standalone/shared/selectors';
import { ModalWrapper } from '@va/util/components';
import { renderIf, someFailed, someInProgress, successOrFailedStatus } from '@va/util/helpers';
import './style.scss';

const UpdatePaymentMethodFormComponent = lazy(() => import('./UpdatePaymentMethodFormComponent'));

class ManagePaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = { tokenLoaded: false };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.tokenLoaded && !prevProps.isModalOpen && this.props.isModalOpen) {
      this.props.getBraintreeAuthToken();
    }
    if (!prevProps.authorizationToken && this.props.authorizationToken) {
      this.setState({ tokenLoaded: true });
    }
  }

  getSubmitBtnType(isFormValid) {
    if (isFormValid || this.props.invalid) {
      return 'submit';
    }

    return 'button';
  }

  renderFooterButtons(isFormValid) {
    const disabledClass = !isFormValid ? 'disabled' : '';

    return (
      <React.Fragment>
        <div onClick={this.onCloseModal}>{this.props.translate('button.close')}</div>
        <button type={this.getSubmitBtnType(isFormValid)} className={`colored footer-button ${disabledClass}`}>
          {this.props.translate(`button.${this.props.modalName}`)}
        </button>
      </React.Fragment>
    );
  }

  onCloseModal() {
    const { getBraintreeAuthTokenStatus, createPaymentMethodStatus, modalName } = this.props;
    if (someInProgress(getBraintreeAuthTokenStatus, createPaymentMethodStatus)) return null;

    this.props.resetRequestStatus('createPaymentMethod');
    this.props.setBrainTreeInstanceLoaded(false);
    this.props.closeModal(modalName);
    //Remove all query params
    appHistory.push(window.location.pathname);
  }

  render() {
    const { getBraintreeAuthTokenStatus, createPaymentMethodStatus, modalName, isSettingActiveWebsiteInProgress } =
      this.props;
    const showFormView = renderIf(
      getBraintreeAuthTokenStatus === apiStatus.SUCCEEDED &&
        createPaymentMethodStatus === apiStatus.NONE &&
        !isSettingActiveWebsiteInProgress,
    );
    const showLoadingView = renderIf(
      someInProgress(getBraintreeAuthTokenStatus, createPaymentMethodStatus) || isSettingActiveWebsiteInProgress,
    );
    const showFeedbackView = renderIf(successOrFailedStatus(createPaymentMethodStatus));

    return (
      <ModalWrapper
        isModalOpen={this.props.isModalOpen}
        closeModal={this.onCloseModal}
        closeMaskOnClick={false}
        showCloseButton={!someInProgress(getBraintreeAuthTokenStatus, createPaymentMethodStatus)}
      >
        <React.Fragment>
          <Suspense fallback={<LoadingPopUpCard />}>
            {showFormView(
              <UpdatePaymentMethodFormComponent
                {...this.props}
                closeModal={this.onCloseModal}
                formName={'managePaymentMethodForm'}
                modalName={modalName}
                renderFooterButtons={(isFormValid) => this.renderFooterButtons(isFormValid)}
              />,
            )}
          </Suspense>

          {showLoadingView(<LoadingPopUpCard />)}
          {showFeedbackView(
            <MessageCard
              modalName={modalName}
              translate={this.props.translate}
              onClose={this.onCloseModal}
              apiError={someFailed(getBraintreeAuthTokenStatus, createPaymentMethodStatus)}
            />,
          )}
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const auth = Selectors.standalone.Api.getApiRequest(state, 'getBraintreeAuthToken');
  const customer = Selectors.standalone.Api.getApiRequest(state, 'getCustomer');
  const website = getWebsite(state);
  return {
    locale: getLocale(state),
    isModalOpen: isModalOpen(state, ownProps.modalName),
    authorizationToken: auth.get('token', null),
    getBraintreeAuthTokenStatus: Selectors.standalone.Api.getApiRequestStatus(state, 'getBraintreeAuthToken'),
    createPaymentMethodStatus: Selectors.standalone.Api.getApiRequestStatus(state, 'createPaymentMethod'),
    isSettingActiveWebsiteInProgress: Selectors.standalone.App.isSettingActiveWebsiteInProgress(state),
    initialValues: Immutable.fromJS({
      companyName: customer.get('companyName', website.companyName),
      firstName: customer.get('firstName', ''),
      lastName: customer.get('lastName', ''),
      vat: customer.get('vat', ''),
      businessSector: website.businessSector,
    }),
  };
};

const mapDispatchToProps = {
  getBraintreeAuthToken: Actions.standalone.Api.getBraintreeAuthToken,
  onSubmitAction: Actions.standalone.Api.createPaymentMethod,
  setBrainTreeInstanceLoaded: Actions.standalone.App.setBrainTreeInstanceLoaded,
  closeModal: closeModal,
  resetRequestStatus: resetRequestStatus,
};

ManagePaymentMethod.propTypes = {
  //Required external props
  modalName: PropTypes.string.isRequired,

  //own props
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmitAction: PropTypes.func.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  getBraintreeAuthToken: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  authorizationToken: PropTypes.string,
  setBrainTreeInstanceLoaded: PropTypes.func.isRequired,
  isSettingActiveWebsiteInProgress: PropTypes.bool,
  getBraintreeAuthTokenStatus: PropTypes.string.isRequired,
};

const ManagePaymentMethodForm = reduxForm({
  form: 'managePaymentMethodForm',
  getFormState: (state) => Selectors.standalone.getFormState(state),
})(ManagePaymentMethod);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ManagePaymentMethodForm));
