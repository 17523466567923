import { countryCodes } from '@va/assets';
import { useTrafficChannelValues } from '@va/dashboard/api-client/traffic-channels';
import { VISITOR_TYPE } from '@va/dashboard/modules/feedback/shared';
import { useGetFunnels } from '@va/dashboard/modules/funnels';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { useTranslate } from '@va/localization';
import { alarmingEventsMap } from '@va/types/events';
import { DefaultFilterOptionType } from '@va/types/filters';
import { Flag } from '@va/util/components';
import { isDefaultWebsiteId } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FunnelFilterValue } from './specific-filter-inputs';

export const usePrepareAsyncAssets = () => {
  useOssOptions();
  useBrowserOptions();
  useTrafficChannelValues('unknown');
  useTrafficChannelValues('social');
  useTrafficChannelValues('email');
};

export const useCountryOptions = () => {
  const translate = useTranslate();

  const options = useMemo(
    () =>
      countryCodes.map((item: { name: string; alpha2: string; alpha3: string }) => ({
        value: item.alpha2,
        label: translate(`country.name.${item.alpha2}`),
        icon: <Flag countryCode={item.alpha2} className='rounded-full h-6 w-6' size='1x1' />,
      })),
    [translate],
  );

  return options;
};

export const useOssOptions = () => {
  const websiteId = useSelector(getInstanceId);
  const {
    data = [],
    isLoading,
    error,
  } = useFetchData<{ label: string; value: string }[]>(
    isDefaultWebsiteId(websiteId) ? null : `/websites/${websiteId}/sessions/oss`,
    { revalidateOnFocus: false, revalidateIfStale: false },
    (data: string[]) => {
      return data.filter((item) => item).map((item) => ({ label: item, value: item }));
    },
  );

  return { data, isLoading, error };
};

export const useBrowserOptions = () => {
  const websiteId = useSelector(getInstanceId);

  return useFetchData<{ label: string; value: string }[]>(
    isDefaultWebsiteId(websiteId) ? null : `/websites/${websiteId}/sessions/browsers`,
    { revalidateOnFocus: false, revalidateIfStale: false },
    (data: string[]) => {
      return data.filter((item) => item).map((item) => ({ label: item, value: item }));
    },
  );
};

export const useDeviceOptions = () => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      {
        value: 'desktop',
        label: translate('panels.latestVisitors.deviceType.desktop'),
      },
      {
        value: 'mobile',
        label: translate('panels.latestVisitors.deviceType.mobile'),
      },
      {
        value: 'phone',
        label: translate('panels.latestVisitors.deviceType.phone'),
      },
    ],
    [translate],
  );
  return options;
};

export const useCompanyOrgType = () => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      {
        value: 'hosting',
        label: translate('filters.options.companyOrgType.hosting'),
      },
      {
        value: 'education',
        label: translate('filters.options.companyOrgType.education'),
      },
      {
        value: 'government',
        label: translate('filters.options.companyOrgType.government'),
      },
      {
        value: 'banking',
        label: translate('filters.options.companyOrgType.banking'),
      },
      {
        value: 'business',
        label: translate('filters.options.companyOrgType.business'),
      },
      {
        value: 'isp',
        label: translate('filters.options.companyOrgType.isp'),
      },
    ],
    [translate],
  );
  return options;
};

export const usePollVisitorTypeOptions = () => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      { value: VISITOR_TYPE.NEW, label: translate('card.latestVisits.newVisitor') },
      { value: VISITOR_TYPE.RETURNING, label: translate('card.latestVisits.returningVisitor') },
    ],
    [translate],
  );
  return options;
};

export const useVisitorTypeOptions = () => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      { value: 0, label: translate('card.latestVisits.newVisitor') },
      { value: 1, label: translate('card.latestVisits.returningVisitor') },
      { value: 2, label: translate('card.latestVisits.conversionVisitor') },
    ],
    [translate],
  );
  return options;
};

export const useAlarmingEventOptions = () => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      { value: alarmingEventsMap.deadClicks, label: translate('filters.visitorActions.alarmingEvent.deadClicks') },
      { value: alarmingEventsMap.rageClicks, label: translate('filters.visitorActions.alarmingEvent.rageClicks') },
      { value: alarmingEventsMap.mouseMoves, label: translate('filters.visitorActions.alarmingEvent.mouseMoves') },
      { value: alarmingEventsMap.scrolls, label: translate('filters.visitorActions.alarmingEvent.scrolls') },
      { value: alarmingEventsMap.uTurns, label: translate('filters.visitorActions.alarmingEvent.uTurns') },
      { value: alarmingEventsMap.refreshes, label: translate('filters.visitorActions.alarmingEvent.refreshes') },
    ],
    [translate],
  );
  return options;
};

export const useBooleanOptions = ({ trueValue, falseValue }: { trueValue: any; falseValue: any }) => {
  const translate = useTranslate();
  const options = useMemo(
    () => [
      { value: falseValue, label: translate('all.filters.options.false') },
      { value: trueValue, label: translate('all.filters.options.true') },
    ],
    [falseValue, translate, trueValue],
  );
  return options;
};

export const useFunnelFilterOptions = (selectedFilterValue: FunnelFilterValue | undefined) => {
  const { data: funnels = [] } = useGetFunnels();

  const translate = useTranslate();

  const mappedFunnels = useMemo(
    () =>
      funnels.map((funnel) => ({
        ...funnel,
        name: translate('labels.funnels.nameText', { name: funnel.name }),
      })),
    [funnels, translate],
  );

  const funnelOptions: DefaultFilterOptionType[] = useMemo(
    () =>
      mappedFunnels.map((funnel) => ({
        value: funnel.key,
        label: funnel.name,
      })),
    [mappedFunnels],
  );

  const selectedFunnel = useMemo(
    () => mappedFunnels.find((funnel) => funnel.key === selectedFilterValue?.id),
    [mappedFunnels, selectedFilterValue?.id],
  );

  const levelOptions: DefaultFilterOptionType[] = useMemo(() => {
    if (!selectedFunnel) return [];
    return selectedFunnel.levels.slice(0, -1).map((level, index) => ({
      value: index + 1,
      label: level.name
        ? translate('labels.funnels.levelText', { name: level.name })
        : translate('labels.funnels.level', { count: index + 1 }),
    }));
  }, [selectedFunnel, translate]);

  return { funnelOptions, levelOptions, funnels, selectedFunnel };
};
