import { useTranslate } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import { FunnelLevelType, FunnelLevelTypes } from '../../funnel.types';
import LevelsDropdown from './LevelsDropdown';

const LevelsCell: React.FC<{ levels: FunnelLevelType[] }> = ({ levels }) => {
  const translate = useTranslate();
  const lastLevelIndex = levels.length - 1;
  const getLevelLabel = (levelIndex: number) => {
    const funnelLevel = levels[levelIndex];
    const urlWithoutProtocol = getUrlWithoutProtocolAndTrailingSlash(funnelLevel.url);
    if (funnelLevel.type === FunnelLevelTypes.URL_PATTERN)
      return translate('funnels.label.pattern', { pattern: urlWithoutProtocol });
    return translate('funnels.labels.link', { link: urlWithoutProtocol });
  };
  return (
    <div className='grid grid-cols-12 text-left items-center whitespace-nowrap overflow-hidden'>
      <div className='w-6'>
        <TooltipWrapper interactive tooltipClassNames='!rounded-18' content={<LevelsDropdown levels={levels} />}>
          <Paragraph colorClassName='text-gray-charcoal'>{levels.length}</Paragraph>
        </TooltipWrapper>
      </div>
      <div className='flex col-span-11'>
        <div className='ml-2 flex flex-col'>
          <LevelIcon />
          <LevelIcon className='rotate-180' />
        </div>
        <div className='flex flex-col truncate'>
          <ParagraphWithTooltip
            weight={fontWeights.medium}
            size={paragraphSizes.tiny}
            colorClassName='text-gray-devil'
            className='truncate'
          >
            {getLevelLabel(0)}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip
            weight={fontWeights.medium}
            size={paragraphSizes.tiny}
            colorClassName='text-gray-devil'
            className='truncate'
          >
            {getLevelLabel(lastLevelIndex)}
          </ParagraphWithTooltip>
        </div>
      </div>
    </div>
  );
};

export default LevelsCell;

const LevelIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 6C13.5 8.48528 11.4853 10.5 9 10.5C6.51472 10.5 4.5 8.48528 4.5 6C4.5 3.51472 6.51472 1.5 9 1.5C11.4853 1.5 13.5 3.51472 13.5 6ZM9.75 11.9536C12.7097 11.5845 15 9.05971 15 6C15 2.68629 12.3137 0 9 0C5.68629 0 3 2.68629 3 6C3 9.05971 5.29027 11.5845 8.25 11.9536V14.25H9.75V11.9536ZM9.75 15.75H8.25V18H9.75V15.75ZM11.25 6C11.25 7.24264 10.2426 8.25 9 8.25C7.75736 8.25 6.75 7.24264 6.75 6C6.75 4.75736 7.75736 3.75 9 3.75C10.2426 3.75 11.25 4.75736 11.25 6Z'
        fill='#969696'
      />
    </svg>
  );
};
