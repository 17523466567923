import { useAddNotification } from '@va/dashboard/util-hooks';
import { useCallback, useEffect } from 'react';
import { FUNNEL_STATUS_ENUM } from '../funnel.types';
import { useEditFunnel } from './funnels-api-client';

const useActivateFunnel = ({ onSuccess }: { onSuccess: () => void }) => {
  const {
    isSucceeded: isEditFunnelApiSucceeded,
    error: editFunnelApiError,
    execute: executeEditFunnel,
  } = useEditFunnel();

  const { showErrorNotification, showSuccessNotification } = useAddNotification();

  const handleActivateFunnel = useCallback(
    (funnelId: string) => {
      executeEditFunnel(funnelId, { status: FUNNEL_STATUS_ENUM.PUBLISHED });
    },
    [executeEditFunnel],
  );

  useEffect(() => {
    if (isEditFunnelApiSucceeded) {
      onSuccess();
      showSuccessNotification();
    }
  }, [isEditFunnelApiSucceeded, onSuccess, showSuccessNotification]);

  useEffect(() => {
    if (editFunnelApiError) {
      showErrorNotification();
    }
  }, [editFunnelApiError, showErrorNotification]);

  return handleActivateFunnel;
};

export default useActivateFunnel;
