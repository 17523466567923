import { useTranslate } from '@va/localization';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { TextInput } from '@va/ui/design-system';
import { useCallback } from 'react';
import useEditFunnelForm, { EditFunnelFormValuesType } from './useEditFunnelForm';

export enum EditFunnelFormInputNames {
  name = 'name',
  conversionRate = 'conversionRate',
}

type EditFunnelModalProps = {
  closeModal: () => void;
  onSuccess?: () => void;
  isOpen: boolean;
  funnelId: string;
  visibleInputItems?: EditFunnelFormInputNames[];
  initialFormValues: EditFunnelFormValuesType;
};

const EditFunnelModal: React.FC<EditFunnelModalProps> = ({
  funnelId,
  closeModal,
  onSuccess,
  isOpen,
  initialFormValues,
  visibleInputItems = [EditFunnelFormInputNames.name, EditFunnelFormInputNames.conversionRate],
}) => {
  const translate = useTranslate();

  const { values, setFieldValue, isLoading, handleChange, handleSubmit, errors } = useEditFunnelForm(
    funnelId,
    initialFormValues,
    onSuccess,
  );

  const isInputVisible = useCallback(
    (inputName: EditFunnelFormInputNames) => {
      return visibleInputItems.includes(inputName);
    },
    [visibleInputItems],
  );

  return (
    <ModalFormWrapper
      isSaveButtonDisabled={isLoading}
      onClose={closeModal}
      title={translate(getEditFunnelModalTitleKey(visibleInputItems))}
      isOpen={isOpen}
      onSubmit={handleSubmit}
    >
      <div className='space-y-3'>
        {isInputVisible(EditFunnelFormInputNames.name) && (
          <TextInput
            error={errors.name}
            name={EditFunnelFormInputNames.name}
            label={translate('funnels.editFunnelName.label')}
            clearField={() => {
              setFieldValue(EditFunnelFormInputNames.name, '');
            }}
            value={values.name}
            onChange={handleChange}
            autoFocus
          />
        )}
        {isInputVisible(EditFunnelFormInputNames.conversionRate) && (
          <TextInput
            type='number'
            error={errors.conversionRate}
            name={EditFunnelFormInputNames.conversionRate}
            label={translate('funnels.details.labels.expectedConversionRate')}
            clearField={() => {
              setFieldValue(EditFunnelFormInputNames.conversionRate, '');
            }}
            value={values.conversionRate ?? undefined}
            onChange={handleChange}
          />
        )}
      </div>
    </ModalFormWrapper>
  );
};

export default EditFunnelModal;

export function getEditFunnelModalInitialValues(
  inputNames: EditFunnelFormInputNames[],
  funnelData: {
    name?: string | null;
    conversionRate?: number | null;
  },
) {
  const initialValues = {} as any;
  if (inputNames.includes(EditFunnelFormInputNames.name)) {
    initialValues[EditFunnelFormInputNames.name] = funnelData.name;
  }
  if (inputNames.includes(EditFunnelFormInputNames.conversionRate)) {
    initialValues[EditFunnelFormInputNames.conversionRate] = funnelData.conversionRate;
  }

  return initialValues;
}

export function getEditFunnelModalTitleKey(inputNames: EditFunnelFormInputNames[]) {
  if (inputNames.length === 1 && inputNames.includes(EditFunnelFormInputNames.name)) {
    return 'funnels.actions.editFunnelName';
  }
  if (inputNames.length === 1 && inputNames.includes(EditFunnelFormInputNames.conversionRate)) {
    return 'funnels.actions.editExpectedConversionRate';
  }
  return 'modal.editFunnel.title';
}
