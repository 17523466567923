import { validationTranslationKeys } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from 'yup';
import { useEditFunnel } from '../funnels-main/funnels-api-client';
import { EditFunnelFormInputNames } from './EditFunnelModal';

export type EditFunnelFormValuesType = {
  [EditFunnelFormInputNames.name]?: string;
  [EditFunnelFormInputNames.conversionRate]?: number | null;
};

const useEditFunnelForm = (funnelId: string, initialValues: EditFunnelFormValuesType, onSuccess?: () => void) => {
  const translate = useTranslate();

  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const { execute: updateFunnel, isLoading, error: apiError, isSucceeded, clearError } = useEditFunnel();

  const { values, setFieldValue, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(translate, Object.keys(initialValues) as EditFunnelFormInputNames[]),
    onSubmit: (values) => {
      updateFunnel(funnelId, values);
    },
  });

  useEffect(() => {
    if (isSucceeded) {
      showSuccessNotification();
      onSuccess && onSuccess();
    }
  }, [isSucceeded, onSuccess, showSuccessNotification]);

  useEffect(() => {
    if (apiError) {
      showErrorNotification();
      clearError();
    }
  }, [apiError, clearError, showErrorNotification]);

  return { values, setFieldValue, errors, isLoading, handleChange, handleSubmit };
};

export default useEditFunnelForm;

function getValidationSchema(translate: Function, fieldNames: EditFunnelFormInputNames[]) {
  const shape = {} as any;
  if (fieldNames.includes(EditFunnelFormInputNames.name)) {
    shape[EditFunnelFormInputNames.name] = yup.string().required(translate(validationTranslationKeys.required));
  }
  if (fieldNames.includes(EditFunnelFormInputNames.conversionRate)) {
    shape[EditFunnelFormInputNames.conversionRate] = yup
      .number()
      .min(0, translate('funnels.validation.minConversionRate'))
      .max(100, translate('funnels.validation.maxConversionRate'));
  }
  return yup.object().shape(shape);
}
