import { createSelector } from '@reduxjs/toolkit';
import { AaasPackage, RootState } from '@va/aaas/shared/types';
import { Website } from '@visitor-analytics/3as-sdk';

export const selectAllowUpgrade = (state: RootState) => state.get('app').allowUpgrade;

export const selectIntpWebsite = (state: RootState) => (state.getIn(['app'])?.intpWebsite as Website) ?? {};

export const getIntpUpgradeMessage = createSelector(selectIntpWebsite, (website) => {
  const { intpWebsiteId, packageName, packageId, intpCustomerId, inTrial, expiresAt, billingInterval } = website;
  return {
    intpWebsiteId,
    packageName,
    packageId,
    intpCustomerId,
    inTrial,
    expiresAt,
    billingInterval,
  };
});

export const selectPackages = (state: RootState, period: 'monthly' | 'yearly') => {
  const packages: AaasPackage[] = state.get('app').packages[period] ?? [];
  const clonedPackages = [...packages];
  return clonedPackages.sort((a, b) => b.touchpoints - a.touchpoints);
};

/**
 * Finds the first package that meets the minimum number of touchpoints
 */
export const getPackageWithMinStps = (state: RootState, minTouchpoints: number) => {
  const packages: AaasPackage[] = state.get('app').packages.monthly ?? [];

  return [...packages].sort((a, b) => a.touchpoints - b.touchpoints).find((p) => p.touchpoints >= minTouchpoints);
};
