import { apiStatus, modalName } from '@va/constants';
import { closeModal, openModal, setCardView } from '@va/dashboard/actions/ui';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getCardView, getPreviousCardView, getUpgradeRequestId, isModalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import {
  getWebsites,
  refreshSubscriptionDetails,
  resetInitialUpgradePlanSliderValue,
  setActiveWebsite,
  setSubscriptionPlan,
  setSubscriptionType,
} from '@va/standalone/shared/actions';
import { subscriptionType, upgradeModalView } from '@va/standalone/shared/constants';
import { appHistory } from '@va/shared/router';
import {
  getApiRequestStatus,
  getPaddleCheckoutEvents,
  getPlanIndex,
  isSettingActiveWebsiteInProgress,
  isWixClaimed,
} from '@va/standalone/shared/selectors';
import { getViewOnWixUrl, renderIf, someFailed, someInProgress } from '@va/util/helpers';
import { getMetaSiteId } from '@va/wix/shared/selectors';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PaddleCheckout from '../UpgradeModal/common/components/paddle-checkout';
import ChangeSubscriptionCard from './ChangeSubscriptionCard';
import CustomUpgradeOfferCard from './CustomUpgradeOffer';
import CustomerInfoForm from './CustomerInfoForm';
import './upgrade-modal.scss';

class UpgradeModalContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.onModalClose = this.onModalClose.bind(this);
    this.handleOnModalViewChange = this.handleOnModalViewChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { upgradeRequestId, setActiveWebsite, updateSubscriptionStatus } = this.props;

    if (upgradeRequestId && upgradeRequestId !== prevProps.upgradeRequestId) {
      setActiveWebsite(upgradeRequestId);
    }
    if (
      prevProps.updateSubscriptionStatus !== updateSubscriptionStatus &&
      (updateSubscriptionStatus === apiStatus.SUCCEEDED || updateSubscriptionStatus === apiStatus.FAILED)
    ) {
      this.handleOnModalViewChange(upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK);
    }
  }

  resetToDefaultValues() {
    const activePlanIndex = Math.max(this.props.activePlanIndex, 1);
    this.props.setSubscriptionType(subscriptionType.YEARLY);
    this.props.resetInitialUpgradePlanSliderValue();
    this.props.setSubscriptionPlan(activePlanIndex, activePlanIndex);
  }

  onModalClose() {
    const { getPaymentPlansStatus, updateSubscriptionStatus } = this.props;
    const paymentInProgress = someInProgress(getPaymentPlansStatus, updateSubscriptionStatus);
    if (paymentInProgress) return;

    this.resetToDefaultValues();
    this.handleOnModalViewChange(upgradeModalView.INITIAL);
    this.props.closeModal();

    this.props.refreshSubscriptionDetails();
    (updateSubscriptionStatus === apiStatus.SUCCEEDED ||
      (this.props.getPaddleCheckoutEvents && this.props.getPaddleCheckoutEvents.event === 'Checkout.Complete')) &&
      this.props.getWebsites();
    //Remove all query params
    const queryParams = this.props?.location?.search;
    if (queryParams) {
      appHistory.push(window.location.pathname);
    }
  }

  handleOnModalViewChange(modalView, previousModalView) {
    this.props.setCardView(modalName.upgrade, modalView, previousModalView);
  }

  getBackCardView(modalView) {
    switch (modalView) {
      case upgradeModalView.CUSTOMER_INFO_FORM:
      case upgradeModalView.UPGRADE_CUSTOM_FORM:
        return upgradeModalView.INITIAL;
      case upgradeModalView.CHECKOUT_FORM:
        return this.props.previousModalView;
      case upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK:
      case upgradeModalView.INITIAL:
        return upgradeModalView.INITIAL;
      default:
        return upgradeModalView.INITIAL;
    }
  }

  render() {
    const {
      modalView,
      getPaymentPlansStatus,
      updateSubscriptionStatus,
      isSettingActiveWebsiteInProgress,
      isWixClaimed,
      isModalOpen,
      metaSiteId,
    } = this.props;

    const paymentPlansFailed = getPaymentPlansStatus === apiStatus.FAILED;
    const updateSubscriptionFailed = updateSubscriptionStatus === apiStatus.FAILED;
    const inProgress = someInProgress(getPaymentPlansStatus, updateSubscriptionStatus);

    // Claimed wix websites should not be able pe access this modal, this is a safe guard because there might still be some places in the application where the modal is opened
    if (isWixClaimed && isModalOpen) {
      const wixUrl = getViewOnWixUrl(metaSiteId);
      window.open(wixUrl, '_blank');
      this.onModalClose();
      return null;
    }

    const showGeneralErrorFeedbackView = renderIf(paymentPlansFailed);
    const showInitialView = renderIf(
      modalView === upgradeModalView.INITIAL &&
        getPaymentPlansStatus === apiStatus.SUCCEEDED &&
        !someFailed(getPaymentPlansStatus, updateSubscriptionStatus) &&
        !isSettingActiveWebsiteInProgress,
    );
    const showCheckoutView = renderIf(
      modalView === upgradeModalView.CHECKOUT_FORM &&
        !inProgress &&
        !someFailed(getPaymentPlansStatus, updateSubscriptionStatus) &&
        !isSettingActiveWebsiteInProgress,
    );
    const showUpgradeCustomView = renderIf(
      modalView === upgradeModalView.UPGRADE_CUSTOM_FORM &&
        !someFailed(getPaymentPlansStatus, updateSubscriptionStatus),
    );
    const showCustomerInfoView = renderIf(modalView === upgradeModalView.CUSTOMER_INFO_FORM);
    const showUpdateSubscriptionFailedView = renderIf(
      modalView === upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK && updateSubscriptionFailed,
    );
    const showUpdateSubscriptionSucceededView = renderIf(
      modalView === upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK && updateSubscriptionStatus === apiStatus.SUCCEEDED,
    );
    const showLoadingView = renderIf(inProgress || isSettingActiveWebsiteInProgress);

    return (
      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={this.onModalClose}
        closeMaskOnClick={modalView !== upgradeModalView.CHECKOUT_FORM}
        className={'upgrade-modal-container'}
        showCloseButton={!inProgress || !isSettingActiveWebsiteInProgress}
        showCloseIconWithBorder
        onBackButtonClick={this.handleOnModalViewChange.bind(this, this.getBackCardView(modalView), modalView)}
        showBackIcon={
          !inProgress &&
          modalView !== upgradeModalView.INITIAL &&
          modalView !== upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK
        }
      >
        <React.Fragment>
          {showCheckoutView(<PaddleCheckout currentModalName={modalName.upgrade} />)}
          {showInitialView(<ChangeSubscriptionCard closeModal={this.onModalClose} />)}
          {showUpgradeCustomView(
            <CustomUpgradeOfferCard
              backToInitialScreen={this.handleOnModalViewChange.bind(
                this,
                upgradeModalView.INITIAL,
                upgradeModalView.UPGRADE_CUSTOM_FORM,
              )}
              hasBackButton
            />,
          )}
          {showCustomerInfoView(
            <CustomerInfoForm
              backToInitialScreen={this.handleOnModalViewChange.bind(
                this,
                upgradeModalView.INITIAL,
                upgradeModalView.CUSTOMER_INFO_FORM,
              )}
              hasBackButton
            />,
          )}
          {showLoadingView(<LoadingPopUpCard />)}
          {showGeneralErrorFeedbackView(<MessageCard modalName={modalName.generalError} apiError />)}
          {showUpdateSubscriptionFailedView(<MessageCard modalName={modalName.updateSubscription} apiError />)}
          {showUpdateSubscriptionSucceededView(<MessageCard modalName={modalName.updateSubscription} />)}
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metaSiteId: getMetaSiteId(state),
    upgradeRequestId: getUpgradeRequestId(state),
    isModalOpen: isModalOpen(state, modalName.upgrade),
    modalView: getCardView(state, modalName.upgrade),
    previousModalView: getPreviousCardView(state, modalName.upgrade),
    getPaymentPlansStatus: getApiRequestStatus(state, 'getPaymentPlans'),
    updateSubscriptionStatus: getApiRequestStatus(state, 'updateSubscription'),
    activePlanIndex: getPlanIndex(state),
    getPaddleCheckoutEvents: getPaddleCheckoutEvents(state),
    isSettingActiveWebsiteInProgress: isSettingActiveWebsiteInProgress(state),
    isWixClaimed: isWixClaimed(state),
    websiteId: getInstanceId(state),
  };
};

const mapDispatchToProps = {
  setActiveWebsite: setActiveWebsite,
  closeModal: () => closeModal(modalName.upgrade),
  openModal: openModal,
  setCardView: setCardView,
  setSubscriptionType: setSubscriptionType,
  setSubscriptionPlan: setSubscriptionPlan,
  refreshSubscriptionDetails: refreshSubscriptionDetails,
  resetInitialUpgradePlanSliderValue: resetInitialUpgradePlanSliderValue,
  getWebsites: getWebsites,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpgradeModalContainer));

UpgradeModalContainer.propTypes = {
  //connect
  openModal: PropTypes.func.isRequired,
  setCardView: PropTypes.func.isRequired,
  setActiveWebsite: PropTypes.func.isRequired,
  setSubscriptionType: PropTypes.func.isRequired,
  setSubscriptionPlan: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  refreshSubscriptionDetails: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalView: PropTypes.string.isRequired,
  previousModalView: PropTypes.string,
  activePlanIndex: PropTypes.number.isRequired,
  getPaymentPlansStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  updateSubscriptionStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  upgradeRequestId: PropTypes.string,
  isSettingActiveWebsiteInProgress: PropTypes.bool.isRequired,
  getWebsites: PropTypes.func.isRequired,
};
