import { FUNNEL_STATUS, TEST_IDS, tabNames, userActions } from '@va/constants';
import { FunnelIcon, PencilEditIcon, PlayIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { Button } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useFunnelsContext } from '../FunnelsContext';
import FunnelOperationsButton from '../funnels-grid-view/FunnelOperationsButton';

const ActionsCell = ({ funnelStatus, funnelId }: { funnelStatus: number; funnelId: string }) => {
  const history = useHistory();
  const translate = useTranslate();
  const isDraft = useMemo(() => funnelStatus === FUNNEL_STATUS.DRAFT, [funnelStatus]);
  const { handleActivateFunnel } = useFunnelsContext();

  const hasEditPermission = useSelector((state) => hasPermission(state, userActions.Edit, tabNames.funnels));

  const tooltip = useMemo(() => {
    if (isDraft && !hasEditPermission) return translate('tooltips.contributorRole');
    if (isDraft && hasEditPermission) return translate('button.editFunnel');
    return translate('button.viewFunnelDetails');
  }, [hasEditPermission, isDraft, translate]);

  const statusTooltip = useMemo(() => {
    if (!hasEditPermission) return translate('tooltips.contributorRole');
    if (!isDraft) return translate('all.funnels.activeFunnel.tooltip');
    return translate('button.activateFunnel');
  }, [hasEditPermission, isDraft, translate]);

  return (
    <div className='flex gap-1'>
      <Button
        color='secondary'
        disabled={isDraft && !hasEditPermission}
        data-testid={isDraft ? TEST_IDS.generic.funnels.editBtn : TEST_IDS.generic.funnels.detailsBtn}
        icon={(_, color) => (isDraft ? <PencilEditIcon color={color} /> : <FunnelIcon color={color} />)}
        onClick={() => {
          if (isDraft) {
            history.push(`/behaviour/funnels/edit/${funnelId}`);
          } else {
            history.push(`/behaviour/funnels/${funnelId}`);
          }
        }}
        tooltip={tooltip}
      />
      <Button
        color='secondary'
        data-testid={TEST_IDS.generic.funnels.activateBtn}
        disabled={!isDraft || !hasEditPermission}
        icon={(_, color) => <PlayIcon color={color} />}
        onClick={() => handleActivateFunnel(funnelId)}
        tooltip={statusTooltip}
      />
      <FunnelOperationsButton funnelId={funnelId} />
    </div>
  );
};

export default ActionsCell;
