import { LocationDescriptor, createBrowserHistory } from 'history';

const history = createBrowserHistory();
const initialPushFn = history.push.bind({});

const handlePush = (initialFn: typeof initialPushFn, path: LocationDescriptor<unknown> | string, state: unknown) => {
  const locationSearch = typeof path === 'string' ? history.location.search : path.search;

  let newPath = typeof path === 'string' ? path : path.pathname;
  newPath = newPath?.concat(locationSearch ?? '');

  return initialFn(newPath ?? '', state);
};

history.push = (path, state) => handlePush(initialPushFn, path, state);

export default history;
